
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Routes, Route } from 'react-router-dom';
import Home from "./pages/Home";
import Marchants from './pages/Marchants';
import About from './pages/About';
import Blog from './pages/Blog'
import Countin from './pages/Countin'
import Partner from './pages/Partner';
import Customer from './pages/Customer'
import Demo from './pages/Demo';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';


function App() {

  return (
    <div className="App">
  
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/merchant' element={<Marchants />}></Route>
        <Route path='/partner' element={<Partner />}></Route>
        <Route path='/about' element={<About />}></Route>
        <Route path='/blog' element={<Blog />}></Route>
        <Route path='/user-signup' element={<Countin />}></Route>
        <Route path='/merchant-signup' element={<Customer />}></Route>
        <Route path='/request-demo' element={<Demo />}></Route>
        <Route path='/privacy-policy' element={<Privacy />}></Route>
        <Route path='/terms-of-use' element={<Terms />}></Route>

      </Routes>
      
    </div>
  );
}

export default App;
