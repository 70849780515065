import React from "react";
import Apple from "../images/app-store.png"
import Play from "../images/google-play.png"
import SpaceGreyMockUp from "../images/spacegreymockup.png"

function Header() {
  // URL to the file you want users to download
  const downloadLink = 'https://play.google.com/store/apps/details?id=com.lucred.lucredmobile';

  return (
    <header id="header">
      <div className="container intro d-flex">
        <div className=" intro-text">
          <div>
            <h1>
              Unlock the Power<br></br> to do more <br />
              <span className="revealUp" id="text1"></span>
            </h1>
            <p>Get up to ₦300,000 in credit limit. Experience freedom, rewards, and endless possibilities promptly.
            </p>

          </div>

          <div className="download-icon">
            <img src={Apple} />

            <a href={downloadLink}>
              <img src={Play}
                alt="Download from Play Store"
                style={{ cursor: 'pointer', width: '200px' }}
              />
            </a>
          </div>
        </div>

        <div className="intro-img">
          <img src={SpaceGreyMockUp} alt='space-grey'></img>
        </div>
      </div>

    </header>
  )
}

export default Header;