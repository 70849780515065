import React from "react";
import Marchants from "../images/marchant-img.png"
import ListCheck from "../images/list-check.png"
import CreditCard from "../images/credit-card.png"
import PaymentCard from "../images/payment-card.png"
import { Link } from "react-router-dom";

function Marchant(){
    return(
    <>
        <div className="container ">
                <div className="marchant-div-wrapper marchant-wrapper d-flex align-items-center">
                    <div className="marchant-div text-white">
                        <h3>Are you a Merchant?</h3>
                        <p>Lucred Pay helps you get more
                            customers. With our Buy Now Pay 
                            Overtime option, you get a 40%
                            increase in sales and no constraints on
                            cashflow. Join over 1000+ pre-signed
                            merchants and enjoy more premium
                            benefits.</p>
                    <Link to='/merchant-signup'>    <button className="text-dark">Hop In</button></Link>
                    </div>
                    <div className="marchant-img">
                        <img src={Marchants} alt='marchant'></img>
                    </div>
            </div>
        
            <div className="card-div-wrapper d-flex justify-content-center">
                <div className="card flex-col">
                    <div className="flex-row">
                        <h3>Seamless <br/>Application</h3>
                        <img src={ListCheck} alt="List Check Card" />
                    </div>
                    <div className="learn-more-btn">
                        <h5>Learn more</h5>
                    </div>                                           
                </div>

                <div className="card">
                    <div className="d-flex">
                        <h3>
                        Accessible
                        Credit
                        </h3>
                        <img src={PaymentCard} alt="Payment Card" />
                    </div>
                    <div className="learn-more-btn">
                        <h5>Learn more</h5>
                    </div>                                           
                </div>
                <div className="card flex-col">
                    <div className="flex-row">
                        <h3>Low Interest <br/> Rates</h3>
                        <img src={PaymentCard} alt="List Check Card" />
                    </div>
                    <div className="learn-more-btn">
                        <h5>Learn more</h5>
                    </div>                                           
                </div>
            </div>
        </div>
    </>
    )
}

export default Marchant;