import React from "react";
import Footer from "../components/Footer";
import Navigation from '../components/navigation';
import SalesImage from '../images/sales-image.png'
import TotalProduct from '../icons/total-product.svg'
import TotalRevenue from '../icons/total-revenue.svg'
import TotalCustomer from '../icons/total-customer.svg'
import TotalTransaction from '../icons/total-transaction.svg'
import LadyLaptop from "../images/lady-laptop.png"
import LadyLaptopText from "../images/lady-laptop-text.png"
import Partners from "../images/partners.png"
import Line from "../images/Line.png"
import CreditCards from '../images/credit-cards.png'
import { Link } from "react-router-dom";


function Marchants() {
    return(
        <div>
            <Navigation />
                <div className="container sales-select">
                    <div className=" d-flex justify-content-between">
                        <div className="sales-text">
                            <h3>Looking to increase sales?</h3>
                            <h6>Join over 1000 merchants on our platform</h6>
                            <p>
                            Make products affordable at no cost to you and enjoy over 40% 
                            sales increase.</p>
                           <Link to='/merchant-signup'> <button>Hop in</button> </Link>
                        </div>
                        <div className=" sales-img">
                            <img src={SalesImage}></img>
                        </div>

                    </div>

                </div>




                <section className="container number-section-wrapper">
                    <div className="line-div">
                        <p>200+ Trusted Partner</p>
                    </div>
                    <div className="number-section-div d-flex">
                        <div className="number-section px-">
                            <div className="number-img-wrapper number-img-wrapper-one d-flex p-3 ">
                                <img src={TotalProduct} alt='total-product' className="number-img-wrapper-one"></img>
                                <p className="ps-3 pt-2 pb-4">Total Product</p>
                            </div>
                            <div className="d-flex number-wrapper-one">
                                <h1>364,138</h1>
                                <div className="number-wrapper-text  ps-2">
                                    <p id="last-day-one"> <span className="ps-5" id="percentage-one">2%</span> <br></br>Last 7 days</p>
                                </div>

                            </div>

                        </div>
                        <div className="number-section px- mt-4">
                            <div className="number-img-wrapper number-img-wrapper-two d-flex p-3 ">
                                <img src={TotalTransaction} alt='total-transaction' className=""></img>
                                <p className="ps-3 pt-2 pb-">Total Transaction</p>
                            </div>
                            <div className="d-flex number-wrapper-two ms-1">
                                <h1>246,769</h1>
                                <div className="number-wrapper-text ps-2">
                                    
                                    <p id="last-day-two"> <span className="ps-5" id="percentage-two">6%</span> <br></br>Last 7 days</p>
                                </div>

                            </div>

                        </div>
                        <div className="number-section px-">
                            <div className="number-img-wrapper number-img-wrapper-three d-flex p-3 ">
                                <img src={TotalRevenue} alt='total-revenue' className=""></img>
                                <p className="ps-3 pt-2 pb-4">Total Revenue</p>
                            </div>
                            <div className="d-flex number-wrapper-three">
                                <h1>624,148</h1>
                                <div className="number-wrapper-text ms-2">
                                    
                                    <p id="last-day-three"><span className="ps-5" id="percentage-three">13%</span><br></br>Last 7 days</p>
                                </div>

                            </div>

                        </div>
                        <div className="number-section px- mt-4">
                            <div className="number-img-wrapper number-img-wrapper-four d-flex p-3 ">
                                <img src={TotalCustomer} alt='total-customer' className=" pt-3"></img>
                                <p className="ps-3 pt-2 pb-">Total Customer</p>
                            </div>
                            <div className="d-flex number-wrapper-four ms-3">
                                <h1>262,434</h1>
                                <div className="number-wrapper-text ps-2 d-flex justify-content-end">
                                    
                                    <p id="last-day-four"><span className="ps-5" id="percentage-four">0.4%</span><br></br>Last 7 days</p>
                                </div>

                            </div>

                        </div>
                    </div>

                </section>



                <section>
                    <div className="container franchise-section my-5 py-5 px-">
                        <div className="franchise-wrapper">
                            <h1>Unleash Your Business Potential with Unbeatable Advantages</h1>
                        </div>
                        <div className="d-flex pt-3">
                            <div className="franchise-texxt-wrapper">
                                <h3>Boost Conversion</h3>
                                <p>With the buy now, pay later option, you can enjoy a 40% increase in average order and turn visitors into buyers.</p>
                            </div>
                            <div className="franchise-texxt-wrapper">
                                <h3> Get Paid Upfront</h3>
                                <p>As a merchant, you get paid upfront. We collect the payments from your customer, risk-free for merchants.</p>
                            </div>
                            <div className="franchise-texxt-wrapper">
                                <h3>Access to Growth</h3>
                                <p>Need growth capital? Lucred can help with that.</p>

                            </div>
                            <div className="franchise-texxt-wrapper">
                                <h3>Easy Payment</h3>
                                <p>Accept payment through multiple channels.</p>
                            </div>
                        </div>

                    </div>
                </section>


                <section>
                    <div className="container">
                        <div className="benefits-text-wrapper text-center">
                            <h3 className="">
                                Enjoy various benefits and exclusive features for your business expansion in an instant
                            </h3>
                        </div>
                        <div className="d-flex justify-content-evenly lady-laptop-wrapper">
                            <div className="ladyLaptop py-3">
                                <img src={ LadyLaptop } alt="lady-laptop" className=""></img>

                            </div>
                            <div className="p-5 ">
                                <div className="lady-text">
                                    <p>Grow your GMV</p>
                                    <img src={ Line } alt='text'></img>
                                    <p>Increase repeat purchases</p>
                                    <img src={ Line } alt='text'></img>
                                    <p>Protect your working capital</p>
                                    <img src={ Line } alt='text'></img>
                                    <p>Make every purchase affordable</p>
                                    <img src={ Line } alt='text'></img>
                                    <p>Compete with larger brands</p>
                                    <img src={ Line } alt='text'></img>
                                    <p>Business, only better</p>
                                </div>
                                
                            </div>
                        </div>

                    </div>
                </section>


                <section className="">
                    <div className="increase-section container">
                        <div className="increase-text">
                            <h3>Thousand of Merchants<br></br>
                                see increase in their<br></br>
                                business
                            </h3>
                        </div>
                        <div className="d-flex justify-content-evenly align-items-end increase-line text-center">
                            <div className="increase one " style={{height: '170px'}}>20%</div>
                            <div className="increase" style={{height: '119px'}}>16%</div>
                            <div className="increase" style={{height: '244px'}}>38%</div>
                            <div className="increase" style={{height: '190px'}}>27%</div>
                        </div>

                    </div>
                </section>

                 <section>
                    <div className="container partners-wrapper">
                        <div className="d-flex justify-content-center">
                            <div className="card ">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div><h3>See Some Of Our Merchants</h3></div>
                                    <div className="partner-img">
                                        <img src={Partners} alt='partners'></img>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="d-flex credit-cards-div align-items-center">
                            <div className="credit-cards-text pt-5 pe-3">
                                <h2>Join Now And Introduce Your Business To Many People</h2>
                                <p>We are ready to assist you in growing your business</p>
                               <Link to='/merchant-signup'> <button>Hop in</button></Link>
                            </div>
                            <div className="credit-cards-img">
                                <img src={CreditCards} alt='credit-cards'></img>
                            </div>

                        </div>

                    </div>
                </section>
            <Footer />
        </div>
    )
}

export default Marchants;

