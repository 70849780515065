import React from "react";
import Logo from "../images/logo.png"
import Head from "../images/lucred-head.png"
import Facebook from "../images/facebook.png"
import Twitter from "../images/twitter.png"
import Youtube from "../images/youtube.png"
import Instagram from "../images/instagram.png"


function Footer() {
    return (
        <div className="container footer-wrapper">

            <div className="d-flex justify-content-between px-5">
                <div className="join-news">
                    <h4>Join Our Newsletters</h4>
                    <span><p>We’ll send you a newsletter once per week</p></span>
                </div>
                <div className="form-div">
                    <input placeholder="Enter your email"></input>
                    <button>subscribe</button>
                </div>
            </div>
            <div className="d-flex footer-text-div justify-content-between px-5 pt-2">
                <div className="footer-text">
                    <img src={Head} alt="lucred" className="bg-white my-2"></img>
                    <p>growing Africa into a <br></br>
                        credit economy
                    </p>
                </div>
                <div className="footer-text">
                    <h4 className="my-3">Product</h4>
                    <p><a className="footer-link" href="/">Credit</a></p>
                    <p><a className="footer-link" href="/merchant">Merchant</a></p>
                    <p><a className="footer-link" href="/partner">Partner</a></p>
                </div>

                <div className="footer-text">
                    <h4 className="my-3">Legal</h4>
                    <p><a className="footer-link" href="/privacy-policy">Privacy Policy</a></p>
                    <p><a className="footer-link" href="/terms-of-use">Terms of Use</a></p>
                    <p><a className="footer-link" href="/">AML policy</a></p>
                    <p><a className="footer-link" href="/">Debit Card</a></p>
                </div>

                <div className="footer-text">
                    <h4 className="my-3">Address</h4>
                    <h4>USA</h4>
                    <p>16192 Coastal Hwy,<br></br>
                        Lewes, DE 19958.</p>
                    <h4>Nigeria</h4>
                    <p>22a Ranch Avenue,<br></br>
                        Ibadan.
                    </p>
                </div>

                <div className="footer-text">
                    <h4 className="my-2">Contact</h4>
                    <p><a className="footer-link" href="tel:07025457747">07025457747</a></p>
                    <p><a className="footer-link" href="mailto:hello@lucred.co">hello@lucred.co</a></p>
                </div>
            </div>
            <div className="bg-white d-flex justify-content-between px-5 py-4 mx-5">
                <div className="logo-wrapper">
                    <img src={Logo} alt="lucred"></img>
                </div>
                <div className="d-flex align-items-center">
                    <div className="d-flex footer-icon ">
                        <img src={Facebook} alt="facebook" className="mx-2"></img>
                        <img src={Twitter} alt="twitter" className="mx-2"></img>
                        <img src={Instagram} alt="instagram" className="mx-2"></img>
                        <img src={Youtube} alt="youtube" className="mx-2"></img>

                    </div>
                    <div className="text-black">
                        <p className="mb-0">2024</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;