import React from "react";
import Navigation from '../components/navigation';
import Footer from '../components/Footer';
import HeadCircle from '../images/head-circle.png'
import Lucred from '../images/lucred.png'
import HandsTogether from '../images/hands-together.png'
import Freepik from '../images/freepik.png'
import OnlineWorld from '../images/online-world.png'
import CustomerRelationship from '../images/customer-relationship.png'
import Wave from '../images/waving.png'
import Send from '../images/send.png'
import Chat from "../components/Chat";


function Countin(){
    return(
        <>

        {/* This page is for new client/user registration */}
            <Navigation />
            <main>
                <div className="container">
                    <section>
                        <div className="maserati-div d-flex">
                            <div className="maserati-text-wrapper d-flex flex-column justify-content-center">
                                {/* <div className="d-flex">
                                    <img src={HeadCircle} alt='lucred-head' className="head-circle"></img>
                                    <img src={Lucred} alt='lucred-head' className="lucred"></img>
                                </div> */}
                                <div className="maserati-text">
                                    <h3>Hop In our Maserati!</h3>
                                </div>
                                <form className="maserati-form d-flex flex-column">
                                    <div className="d-flex flex-column mb-4">
                                        <label>Full Name</label>
                                        <div className="d-flex justify-content-between" id="fullname">
                                            <input type="text" placeholder="     First Name"></input>
                                            <input type="text" placeholder="     Last Name"></input>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column mb-4">
                                        <label >Email</label>
                                        <input type="email" placeholder="      comicfooty@gmail.com"></input>
                                    </div>
                                    <div className="d-flex flex-column mb-4">
                                        <label>Phone Number</label>
                                        <input type="text" placeholder="      08030601026"></input>
                                    </div>
                                    {/* <div className="d-flex flex-column mb-4">
                                        <label>Password</label>
                                        <input type="password" placeholder="      *********"></input>
                                    </div> */}
                                    <div className="d-flex justify-content-end">
                                        <button className="d-flex justify-content-end">Submit</button>
                                    </div>
                                </form>

                            </div>
                            <div className="maserati-img not-show">
                                <img src={HandsTogether} alt='hands-together'></img>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="support-wrapper">
                            <div className="text-center support-text">
                                <h3>WE WORK SEAMLESSLY WITH OUR CLIENTS FOR THE LONG-TERM</h3>
                                <p>Reach out to us if you need any help</p>
                            </div>
                            <div className="d-flex justify-content-evenly support-div text-center ">
                                <div className="card">
                                    <h4 >Let's Talk</h4>
                                    <p className="not-show">Have a chat with a <br></br>member of our team.</p>
                                    <p className="not-show"><span>Call Us </span> .</p>
                                    <img src={Freepik} alt='freepik'></img>

                                </div>
                                <div className="card middle-card">
                                    <h4>Support</h4>
                                    <p className="not-show">Get involved in <br></br>making the dream <br></br>a reality today.</p>
                                    <p className="not-show"><span>Follow Us </span> .</p>
                                    <img src={OnlineWorld} alt='freepik'></img>

                                </div>
                                <div className="card">
                                    <h4>We are Social</h4>
                                    <p className="not-show">We're here <br></br>to help with <br></br>any questions <br></br>or queries.</p>
                                    <p className="not-show"> <span>Help & Support</span> .</p>
                                    <img src={CustomerRelationship} alt='freepik'></img>

                                </div>

                            </div>
                        </div>
                    </section>
                    {/* <section>
                        <div className="send-wave">
                            <div className="service-card-wrapper d-flex ">
                                <div className="card">
                                    <div className="d-flex justify-content-center chat-div ">
                                        <div className="chat-text">
                                            <p>Chat with us</p>
                                        </div> 
                                        <div className="px-3 ">
                                            <img src={Wave} alt="waving" className="pb-2"></img>
                                        </div>
                                    </div>
                                </div>
                                <div className="send-div ">
                                    <img src={Send} alt="send"></img>
                                </div>
                            </div>
                        </div>

                    </section> */}

                </div>
            </main>
            <Chat />
            <Footer />

        </>
    )
}

export default Countin;