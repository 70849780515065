import React from "react";
import FeaturePhoto from "../images/feature-background.png"
import { Link } from "react-router-dom";


function Feature() {
    return (
        <div className="feature-div text-center px-5 pt-4">
            <div className="feature-text mt-3 bg-feature">
                <img src={FeaturePhoto} alt="icon" className="icon-image w-100 h-100"></img>
                <div className="text-wrapper">
                    <h2> Built to help you thrive </h2>
                    <p>Experience a seamless online platform designed with your convenience in mind. No lengthy paperwork or time-consuming processes. Just a few simple steps, and you'll have access to funds that open doors to over a thousand merchants.</p>
                    <a href="https://onelink.to/nhzvzw"> <button>Count me in</button> </a>
                </div>
            </div>

        </div>
    )
}

export default Feature;