import React from "react";
import Wave from "../images/waving.png"
import Send from "../images/send.png"



function Chat({ props }){
    return(
        <>
          <section>
                    <div className="container send-wave">
                        <div className="service-card-wrapper d-flex " id="">
                            <div className="card">
                                <div className="d-flex justify-content-center chat-div ">
                                    <div className="chat-text">
                                        <p>{props}</p>
                                    </div> 
                                    <div className="px-3 ">
                                        <img src={Wave} alt="waving" className="pb-2"></img>
                                    </div>
                                </div>
                            </div>
                            <div className="send-div ">
                                <img src={Send} alt="send"></img>
                            </div>
                        </div>
                    </div>
                </section>  
        </>
    )
}


export default Chat;