import React from "react";
import List from "../images/list.png"
import Income from "../images/income.png"
import Check from "../images/check-mark.png"
import Payment from "../images/payment-method.png"


function Steps(){
    return(
        <div className="container "> 
            <div className="steps">
                <h2>How Lucred works</h2>
                <p>our application process is very fast and seamless. our approach to this is to make getting credit easy as logging into your bank mobile app</p>

            </div>
            <div className=" d-flex justify-content-center steps-content">
                <div className="steps-select-wrapper d-flex">
                    <div className="steps-select">
                        <div className="icon-wrapper py-5 d-flex justify-content-end pe-5">
                            <div className="steps-icon d-flex justify-content-center ">
                                <img src={List} alt='list' ></img>
                            </div>
                        </div>
                        <div className="steps-text py-5">
                            <div className="px-">
                                <h3>Get Approved</h3>
                                <p>No more waiting in anticipation. Our cutting-edge algorithms swiftly review your information, ensuring speedy approvals that'll leave you amazed.</p>
                            </div>
                        </div>
                        <div className="icon-wrapper py-5 d-flex justify-content-end pe-5">
                            <div className="steps-icon d-flex justify-content-center">
                                <img src={Income} alt='list' ></img>
                            </div>
                        </div>
                        <div className="steps-text py-5">
                            <div className="px-">
                                <h3>Repay</h3>
                                <p>Repaying your credit has never been easier. Our APR model ensures accurate interest calculations each day, empowering you to settle your dues anytime and still reap the rewards.</p>
                            </div>
                        </div>
                    </div>
                    <div className="step-break-line"></div>
                    <div className="step-text-wrapper">
                    <div className="steps-text py-5">
                        <div className="px-">
                            <h3>Apply</h3>
                            <p>In mere moments, we analyze your data and work our magic to get you approved. It's quicker than saving a contact on your phone!
</p>
                        </div>
                    </div>
                    <div className="icon-wrapper py-5 ps-5">
                        <div className="steps-icon d-flex justify-content-center ">
                            <img src={Check} alt='list' ></img>
                        </div>
                    </div>
                    
                    <div className="steps-text py-5">
                        <div className="px-">
                            <h3>Spend</h3>
                            <p>With 1000+ merchants available on our platform, you have a plethora of options to shop from. You can also use Lucred in-store or on third party websites. Debit card coming soon</p>
                       </div>
                    </div>
                    <div className="icon-wrapper py-5 ps-5">
                        <div className="steps-icon d-flex justify-content-center">
                            <img src={Payment} alt='list' ></img>
                        </div>
                    </div>
                </div>
                </div>
                
                
            </div>

        </div>
    )
}

export default Steps;