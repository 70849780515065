import React from "react";
import Support from "../images/headphone.png"
import Shopping from "../images/shopping.png"
import Discount from "../images/discount.png"
import GraySpace from "../images/space-gray.png"

function Select(){
    return(
    <>
        <div className="container select">
            <div className="card-shadow ">
                <div className="card-div-select py-5">
                    <div className="card ">
                        <div className="d-flex pt-3 px-4">
                            <img src={Shopping} alt="shopping" className="-3"></img>
                            <h4>Shopping</h4>
                        </div>
                    </div>
                    <div className="card my-5 card-div-one">
                        <div className="d-flex pt-3 px-4">
                            <img src={Discount} alt="shopping" className=""></img>
                            <h4>Discount</h4>
                        </div>
                    </div>
                    <div className="card card-div-two">
                        <div className="d-flex pt-3 px-4">
                            <img src={Support} alt="shopping"></img>
                            <h4>Support</h4>
                        </div>
                    </div>
                </div>
                <div className="space-gray-wrapper">
                    <img src={GraySpace} className=""></img>

                </div>
            </div>
            
        </div>
        
    </>
    )
}

export default Select;