import React from "react";
import Navigation from '../components/navigation';
import Header from '../components/Header';
import Feature from '../components/Feature';
import Steps from '../components/Steps'
import Marchant from '../components/Marchant';
import Select from '../components/Select'
import Service from '../components/Service'
import Footer from '../components/Footer';
import Chat from "../components/Chat";


function Home(){
    return(
        <>
            <Navigation />
            <Header />
            <Feature />
            <Steps />
            <Marchant />
            <Select />
            <Service />
            <Chat props="Chat with us"/>
            <Footer />

        </>
    )
}

export default Home