import React from "react";
import Footer from "../components/Footer";
import Navigation from "../components/navigation";
import Meeting from "../images/meeting.png"
import GirlOrange from "../images/girl-orange.png"
import Growth from "../images/growth.png"
import Chart from "../images/chart.png"
import Transaction from "../images/latest-transactions.png"
import Tunde from "../images/tunde-agboola.png"

import Facebook from "../images/facebook.png"
import Twitter from "../images/twitter.png"
import Youtube from "../images/youtube.png"
import Instagram from "../images/instagram.png"
import LadyPhone from "../images/lady-phone.png"
import Chat from "../components/Chat"
import { Link } from "react-router-dom";

function Privacy() {
    return (
        <>
            <Navigation />
            <main className="container">
                {/* first section */}
                <section>
                    <div class="wpb_wrapper">
                        <div class="wpb_text_column wpb_content_element ">
                            <div class="wpb_wrapper">
                                <h1><b>Introduction</b></h1>
                                <p><span style={{ fontWeight: 400 }}>This privacy policy (this “Privacy Policy”) explains how personal information is collected, used, stored, and disclosed by Lucred Technology Limited, (“Lucred” “we,” “us,” and “our”). This Privacy Policy applies to users (individually referred to as “you”) of our websites, applications, and other services (including opening an account, using our card or services for account holders) to which this Privacy Policy is posted (collectively, our “Services”).</span></p>
                                <p><span style={{ fontWeight: 400 }}>This Privacy Policy is part of our Terms of Use. By accessing or using our Services, you agree to this Privacy Policy and our Terms of Use. The provisions contained in this Privacy Policy supersede all previous notices and statements regarding our privacy practices with respect to our Services. If you do not agree to every provision of this Privacy Policy and our Terms of Use, you may not access or use our Services.</span></p>
                                <p><span style={{ fontWeight: 400 }}>This Privacy Policy applies to your use of (regardless of means of access) our Services. You may access or use our Services through a desktop, laptop, mobile phone, tablet, or other consumer electronic device (each, a “Device”).</span></p>
                                <h1><b>Consent</b></h1>
                                <h2><b>We’ll ask for your consent to:</b></h2>
                                <ul>
                                    <li><span style={{ fontWeight: 400 }}>record any issues you want us to know about, like information about your health, so we know how best to support you.</span></li>
                                    <li><span style={{ fontWeight: 400 }}>tell you about our products and services, and those of our partners by email or push notification if we think they’re of interest to you. You can unsubscribed from these email or via the app. (If you don’t want to see promotions in the app, you can opt out in your app ‘Settings’).</span></li>
                                    <li><span style={{ fontWeight: 400 }}>help protect you against fraud by tracking the location of your phone if you’ve authorized.</span></li>
                                    <li><span style={{ fontWeight: 400 }}>show your profile picture in the app if you add one.</span></li>
                                    <li><span style={{ fontWeight: 400 }}>share information about you with companies we work with when we need your permission.</span></li>
                                </ul>
                                <p><b>You don’t have to share information about yourself if you don’t want to. But if you don’t, you may not be able to use some (or any) of our services.</b></p>
                                <h1><b>How We Collect Data</b></h1>
                                <h2><b>Information You Provide Us</b></h2>
                                <p><b>When you sign up for a Lucred Account</b></p>
                                <p><span style={{ fontWeight: 400 }}>Personal details like your name, date of birth and place of birth.</span></p>
                                <p><span style={{ fontWeight: 400 }}>Contact details like your home address, email, phone number and BVN. Information about your indenture, such as a copy of your ID document. Financial details, such as your employment status and the industry you work in, annual income, number of dependents, residential status and monthly housing costs.</span></p>
                                <p><b>When you sign up for or use our services</b></p>
                                <p><span style={{ fontWeight: 400 }}>Details you give us when you sign up for a specific service. If you apply for card or credit we will ask for details about financial circumstances and reason for borrowing.</span></p>
                                <p><span style={{ fontWeight: 400 }}>Details you give us which we pass to our partners when you let us know you’re interested in their services. Information you give us through our chat platforms or disclose to our customer service representatives. Answers you give to surveys about Lucred and our services.</span></p>
                                <h2><b>Information we collect or generate if you get in touch</b></h2>
                                <p><b>If you use other ways to get in touch other than chatting with a customer service representative, we collect the following information so we can answer your questions or take action.</b></p>
                                <p><span style={{ fontWeight: 400 }}>The phone number you’re calling from and information you give us during the call (we record all calls).</span></p>
                                <p><span style={{ fontWeight: 400 }}>The email address you use and contents of your email (and any attachments).</span></p>
                                <p><span style={{ fontWeight: 400 }}>Public details from your social media profile (like Facebook, Instagram or Twitter) if you reach out to us via these platforms, and the contents of your messages or posts to us.</span></p>
                                <h2><b>Information we collect or generate when you use the app and our services</b></h2>
                                <p><b>We collect this information to give you services in a safe and lawful way, and to keep improving them. This includes:</b></p>
                                <ul>
                                    <li><span style={{ fontWeight: 400 }}>details about payments to and from your Lucred account, card and any loans or credits you take out.</span></li>
                                    <li><span style={{ fontWeight: 400 }}>details about services from us and our partners that you express interest in.</span></li>
                                    <li><span style={{ fontWeight: 400 }}>details about you use our app.</span></li>
                                </ul>
                                <p><span style={{ fontWeight: 400 }}>Personal information from you, which may include your name, email address, mobile phone number, banking information, and other information that identifies you (collectively, “Personal Information”).</span></p>
                                <h2><b>Information From Third Parties and External sources</b></h2>
                                <p><b>When you sign up for a Seupe account, we search your record at;</b><span style={{ fontWeight: 400 }}> a credit reference or consumer reporting agency.</span></p>
                                <p><span style={{ fontWeight: 400 }}>Fraud prevention companies and agencies and KYC (Know Your Customer) and AML (Anti Money Laundering) service providers to fulfil our legal duties.</span></p>
                                <h2><b>Information Automatically Collected</b></h2>
                                <p><b>We (or our service providers acting on our behalf) may collect information about your use of our Services. This information may include Personal Information as well as statistical information that does not identify you (“Analytics”). Some Analytics may be correlated with Personal Information.</b></p>
                                <p><b>When Analytics are, directly or indirectly, associated or combined with Personal Information, such Analytics will be considered Personal Information for purposes of this Privacy Policy.</b></p>
                                <p><b>Information that we automatically collect in connection with your access or use of our Services may include:</b></p>
                                <p><span style={{ fontWeight: 400 }}>Device Information: We may collect Device-specific information (such as hardware model, operating system version, unique Device identifiers, and mobile network Information, including your mobile phone number). We may associate your Device identifiers or mobile phone number with your Lucred account.</span></p>
                                <p><span style={{ fontWeight: 400 }}>Log Information: We may record or log information from your Devices, their software, and your activity accessing or using our Services. This information may include:</span></p>
                                <p><span style={{ fontWeight: 400 }}>The mobile network and operating system you use, so we can analyse how our app works and fix any issues</span></p>
                                <p><span style={{ fontWeight: 400 }}>The Device’s Internet Protocol (“IP”) address</span></p>
                                <p><span style={{ fontWeight: 400 }}>Identification numbers associated with your Devices</span></p>
                                <p><span style={{ fontWeight: 400 }}>Device event information, such as crashes, system activity, and hardware settings</span></p>
                                <p><span style={{ fontWeight: 400 }}>Your location if you’ve authorized tracking, so we can help protect you against fraud.</span></p>
                                <p><span style={{ fontWeight: 400 }}>Date and time stamps of transactions</span></p>
                                <p><span style={{ fontWeight: 400 }}>System configuration information</span></p>
                                <p><span style={{ fontWeight: 400 }}>Other interactions with our Services</span></p>
                                <p>Your phone SMS for financial profiling</p>
                                <p><span style={{ fontWeight: 400 }}>Your phone contacts whether or not they use Lucred and if you’ve authorized access to your phone book.</span></p>
                                <h2><b>Information Collected Through Cookies and Similar Technologies</b></h2>
                                <p><b>We use cookies to personalize our Services for you and to collect aggregate information about usage of our Services. A cookie is a text file or other local storage identifier provided by your browser or associated applications. We use cookies for record-keeping purposes and to enhance the quality of your use of our Services. The cookies assign random, unique numbers to your Devices to enable our systems to recognize your Devices and to allow us to see how you use our Services. Additional general information about cookies and how they work is available at www.allaboutcookies.org.</b></p>
                                <p>&nbsp;</p>
                                <p><b>The cookies we use in connection with our Services include:</b></p>
                                <p><span style={{ fontWeight: 400 }}>Session cookies: Session cookies are temporary cookies that expire and are automatically erased whenever you close your browser window. We use session cookies to grant users access to content and to enable actions they must be logged into their Lucred account to perform.</span></p>
                                <p><span style={{ fontWeight: 400 }}>Persistent cookies: Persistent cookies usually have an expiration date in the distant future and remain in your browser until they expire or you manually delete them. We use persistent cookies to better understand usage patterns so we can improve our Services. For example, we may use a persistent cookie to associate you with your Lucred account or to remember your choices for our Services.</span></p>
                                <p><span style={{ fontWeight: 400 }}>Third-party cookies: We permit certain third parties to place cookies through our Services to provide us with better insights into the use of our Services and user demographics and to advertise our Services to you. These third parties may collect information about your online activities over time and across different websites when you access or use our Services. For example, we utilize Google Analytics to analyze usage patterns for our Services. Google Analytics generates a cookie to capture information about your use of our Services, which Google uses to compile reports on website activity for us and to provide other related services. Google may use a portion of your IP address to identify its cookie, but this will not be associated with any other data held by Google. We may also permit third-party service providers to place cookies for our Services, as indicated above, to perform analytic or marketing functions where you are notified of them and you have consented to the usage. We do not control the use of such third-party cookies or the resulting information, and we are not responsible for any actions or policies of such third parties.</span></p>
                                <p><span style={{ fontWeight: 400 }}>By accessing or using our Services, you consent to the placement of cookies on your Devices as described in this Privacy Policy. If you prefer not to receive cookies through our Services, you may control how your browser responds to cookies by adjusting the privacy and security settings of your web browser. Unless you set your browser settings to refuse all cookies, our system may issue cookies when you access or use our Services. If you set your browser settings to refuse all cookies, the performance of certain features of our Services may be limited or not work at all.</span></p>
                                <h1><b>Data Collected</b></h1>
                                <h2><b>How We Use Information</b></h2>
                                <p><b>The Nigerian Data Protection Laws say we need to have a lawful basis for using your personal data. At least one of the following must apply: contractual or legal duty, legitimate interest, public interest, vital individual interest or consent. In this section, we explain which one we rely on to use your data in a certain way.</b></p>
                                <p><b>We need to use your data for a contract we have with you, or to enter into a contract with you.</b> <b>We use details about you to:</b></p>
                                <ul>
                                    <li><span style={{ fontWeight: 400 }}>Consider your application.</span></li>
                                    <li><span style={{ fontWeight: 400 }}>Give you the services we agreed in line with our terms and conditions.</span></li>
                                    <li><span style={{ fontWeight: 400 }}>Send you messages about your account and other services you use if you get in touch, or we need to tell you about something.</span></li>
                                    <li><span style={{ fontWeight: 400 }}>Exercise our rights under contracts we’ve entered into with you, like managing, collecting and recovering money you owe us.</span></li>
                                    <li><span style={{ fontWeight: 400 }}>Investigate and resolve complaints and other issues.</span></li>
                                </ul>
                                <p><b>We need to use your data to comply with the law. We:</b></p>
                                <p><span style={{ fontWeight: 400 }}>confirm your identity when you sign up or get in touch.</span></p>
                                <p><span style={{ fontWeight: 400 }}>Check your record at fraud prevention agencies.</span></p>
                                <p><span style={{ fontWeight: 400 }}>Prevent illegal activities like money laundering, tax evasion and fraud.</span></p>
                                <p><span style={{ fontWeight: 400 }}>Check your credit history and ask about your reasons for applying for our card or financial products and other details about your financial circumstances so that we can make responsible lending decisions.</span></p>
                                <p><span style={{ fontWeight: 400 }}>Keep records of information we hold about you in line with legal requirements.</span></p>
                                <p><span style={{ fontWeight: 400 }}>Adhere to banking laws and regulations (this means we sometimes need to share customer details with regulators, tax authorities, law enforcement or other third parties).</span></p>
                                <p><b>When it’s in our legitimate interest. We need to use your data for our legitimate interests, or those of a third party. This means using data in a way that you might expect us to, for a reason which is in your and/or our (or a third party’s) interest and which doesn’t involve overriding your privacy rights.</b></p>
                                <p><b>Product development and marketing: We:</b></p>
                                <p><span style={{ fontWeight: 400 }}>We check your record at credit reference agencies when you sign up to see if we can offer you our card or credit tell you about products and services through the app or other channels, like social media companies, based on how you use our products and services and other information we hold about you. We do this so we can make sure our marketing is useful. That includes instructing platforms to show or not to show Lucred adverts to existing customers. We don’t share any identifying information with social media companies other than your mobile advertising ID. You can disable or reset your mobile advertising ID in your device’s operating system.</span></p>
                                <p><span style={{ fontWeight: 400 }}>Track, analyse, and improve the service we give you and other customers and how you respond to ads we show. We may ask for feedback if you’ve shown interest in a service. We do this so that we can make our products better and understand how to market them.</span></p>
                                <p><b>Special features. We:</b></p>
                                <p><span style={{ fontWeight: 400 }}>Show where you were when you use the Lucred card or account. Give report on how you’ve spent on your Lucred card.</span></p>
                                <p><b>Security and business management:</b></p>
                                <ul>
                                    <li><span style={{ fontWeight: 400 }}>protect the rights, property, or safety to us, our customers or others.</span></li>
                                    <li><span style={{ fontWeight: 400 }}>Carry out security and maintenance checks to make sure our app, website and services run smoothly for you.</span></li>
                                    <li><span style={{ fontWeight: 400 }}>Manage Lucred’s business risk and financial affairs and protect our customers and staff.</span></li>
                                </ul>
                                <p><b>Companies that give services to us. We:</b></p>
                                <p><span style={{ fontWeight: 400 }}>share your information with companies so they can help us provided our services to you.</span></p>
                                <h1><b>Personal Data</b></h1>
                                <h2><b>Who we share your data with</b></h2>
                                <p><b>Companies that give services to us. Here we mean companies that help us provide services you use, and need to process details about you for this reason. We share as little information as we can and encrypt and/or make it impossible for you to be identified by the recipient where possible (for instance by using a User ID rather than your name).</b></p>
                                <ul>
                                    <li><span style={{ fontWeight: 400 }}>companies that make our Lucred cards.</span></li>
                                    <li><span style={{ fontWeight: 400 }}>card producers and networks, like MasterCard.</span></li>
                                    <li><span style={{ fontWeight: 400 }}>Know Your Customer (KYC) service providers that help us with identity verification or fraud checks.</span></li>
                                    <li><span style={{ fontWeight: 400 }}>cloud computing power and storage providers.</span></li>
                                    <li><span style={{ fontWeight: 400 }}>our business intelligence and analytics platform provider.</span></li>
                                    <li><span style={{ fontWeight: 400 }}>companies that help us with functional analytics (to help us solve technical issues with the app for instance).</span></li>
                                    <li><span style={{ fontWeight: 400 }}>companies that help us with marketing (but we won’t share identifiable personal data with third parties for their own direct marketing unless you give us permission, and you can opt out any time).</span></li>
                                    <li><span style={{ fontWeight: 400 }}>software companies that we use for emailing you like, or for processing and storing email communications with you like.</span></li>
                                    <li><span style={{ fontWeight: 400 }}>companies that help us with customer support.</span></li>
                                    <li><span style={{ fontWeight: 400 }}>companies that offer benefits or rewards through special programmes you sign up to via the app.</span></li>
                                </ul>
                                <p><b>Credit reference agencies. Credit reference agencies (CRAs) give lenders information about borrowers to help them make responsible lending decisions. Banks share details about their customers to help CRAs maintain up-to-date information about people’s financial status. We work with some of these CRAs.</b></p>
                                <p><span style={{ fontWeight: 400 }}>When you sign up and for as long as you’re a customer, we’ll exchange details about you with CRAs. This includes:</span></p>
                                <ul>
                                    <li><span style={{ fontWeight: 400 }}>your name, address and date of birth.</span></li>
                                    <li><span style={{ fontWeight: 400 }}>accounts you have, including when you opened them and money going into them (if you owe us money, we’ll also share your balance).</span></li>
                                    <li><span style={{ fontWeight: 400 }}>credit applications you’ve made and limits you’ve turned on.</span></li>
                                    <li><span style={{ fontWeight: 400 }}>if you’ve borrowed, details of your loan and repayments (like whether you repay in full and on time).</span></li>
                                    <li><span style={{ fontWeight: 400 }}>fraud prevention information.</span></li>
                                </ul>
                                <p><b>We’ll use this information to comply with our legal duties and when it’s in our ‘legitimate interest’ to:</b></p>
                                <ul>
                                    <li><span style={{ fontWeight: 400 }}>verify your identity and make sure what you’ve told us is true.</span></li>
                                    <li><span style={{ fontWeight: 400 }}>help detect and prevent fraud and money laundering.</span></li>
                                    <li><span style={{ fontWeight: 400 }}>assess whether you can afford to make repayments if you borrow.</span></li>
                                    <li><span style={{ fontWeight: 400 }}>manage your account with us.</span></li>
                                    <li><span style={{ fontWeight: 400 }}>trace and recover debts.</span></li>
                                    <li><span style={{ fontWeight: 400 }}>make sure our offers are relevant for you.</span></li>
                                </ul>
                                <p><b>Fraud prevention agencies. When you apply for an account, we check your record with fraud prevention agencies (FPAs). During the application process and after you become a customer, we may share information about you with them to help prevent fraud and money laundering when it’s in our ‘legitimate interest’. If we detect fraud, we may stop activity on your account or block access. Other organisations may use information we share with FPAs about fraud to refuse their services, finance or employment.</b><span style={{ fontWeight: 400 }}> Anyone you give us permission to share it with. We tell you in the app when we need your consent to share your data with: companies that introduce their own services via the Lucred app, like energy switching, insurance or remortgaging. other apps. other banks if you use account switching or aggregation services. people you’ve asked to represent you, like solicitors. Law enforcement and other external parties. We may share your details with: authorities that spot and stop financial crime, money laundering, terrorism and tax evasion if the law says we have to, or if it’s necessary for other reasons. the police, courts or dispute resolution bodies if we have to. other banks to help trace money if you’re a victim of fraud or other crimes or if there’s a dispute about a payment. any other third parties where necessary to meet our legal obligations. We may also share your details with people or companies if there’s a corporate restructure, merger, acquisition or takeover of our company.</span></p>
                                <h1><b>Personal Data Principles</b></h1>
                                <h2><b>Do-Not-Track Signals</b></h2>
                                <p><span style={{ fontWeight: 400 }}>Do Not Track (“DNT”) is an optional browser setting that allows you to express your preferences regarding tracking by advertisers and other third parties. We do not use technology that recognizes DNT signals from your web browser.</span></p>
                                <h2><b>Opt-Out Rights</b></h2>
                                <p><span style={{ fontWeight: 400 }}>If you do not wish to receive offers or other notices from us in the future, you can “opt out” by contacting us as indicated at the end of this Privacy Policy or by following the “unsubscribe” instructions in any communication you receive from us. Please be aware that you are not able to opt out of receiving communications about your Lucred account or related transactions with us.</span></p>
                                <h2><b>Accessing Your Information</b></h2>
                                <p><span style={{ fontWeight: 400 }}>You must notify us of any change in your Personal Information by updating your Lucred account profile through our Services. Any changes will affect only future uses of your Personal Information.</span></p>
                                <p><span style={{ fontWeight: 400 }}>Subject to applicable law, which might, from time to time, oblige us to store your Personal Information for a certain period of time, we will respect your wishes to correct inaccurate information. Otherwise, we will hold your Personal Information for as long as we believe it will help us achieve our objectives as detailed in this Privacy Policy.</span></p>
                                <p><span style={{ fontWeight: 400 }}>You can ask us whether we are storing your Personal Information and you can ask to receive a copy of that Personal Information. Before sending you any Personal Information, we will ask you to provide proof of your identity. If you are not able to provide proof of your identity, we reserve the right to refuse to send you any Personal Information. We will respond as quickly as we can to your requests for details of Personal Information we hold about you.</span></p>
                                <h2><b>Information You Share Socially</b></h2>
                                <p><span style={{ fontWeight: 400 }}>Our Services may allow you to connect and share your actions, comments, content, and information publicly or with friends. We are not responsible for maintaining the confidentiality of any information you share publicly or with friends.</span></p>
                                <p><span style={{ fontWeight: 400 }}>Our Services may also allow you to connect with us on, share on, and use third-party websites, applications, and services. Please be mindful of your personal privacy needs and the privacy needs of others, as you choose whom to connect with and what to share and make public. We cannot control the privacy or security of information you choose to make public or share with others. We also do not control the privacy practices of third parties. Please contact those sites and services directly if you want to learn about their privacy practices.</span></p>
                                <h2><b>Security</b></h2>
                                <p><span style={{ fontWeight: 400 }}>We have, and require our third-party service providers that receive Personal Information from us to have, a comprehensive written information security program that contains administrative, technical, and physical safeguards for our respective physical facilities and in our respective computer systems, databases, and communications networks that are reasonably designed to protect information contained within such systems from loss, misuse, or alteration. When your bank account information is transmitted via our Services, it will be protected by encryption technology, such as Secure Sockets Layer (SSL).</span></p>
                                <p><span style={{ fontWeight: 400 }}>No method of electronic transmission or storage is 100% secure. Therefore, we cannot guarantee absolute security of your Personal Information. You also play a role in protecting your Personal Information. Please safeguard your username and password for your Lucred account and do not share them with others. If we receive instructions using your Lucred account login information, we will consider that you have authorized the instructions. You agree to notify us immediately of any unauthorized use of your Lucred account or any other breach of security. We reserve the right, in our sole discretion, to refuse to provide our Services, terminate Lucred accounts, and to remove or edit content.</span></p>
                                <h2><b>Protecting Children’s Privacy</b></h2>
                                <p><span style={{ fontWeight: 400 }}>Our Services are not directed, or intended to be attractive, to children under the age of 18. We do not knowingly collect Personal Information from children under the age of 18. If you are under the age of 18, do not use our Services or submit any information to us.</span></p>
                                <h2><b>Links to Third-Party Websites</b></h2>
                                <p><span style={{ fontWeight: 400 }}>When you use our Services, you may be directed to other websites that are beyond our control. We may also allow third-party websites or applications to link to our Services. We are not responsible for the privacy practices of any third parties or the content of linked websites, but we do encourage you to read the applicable privacy policies and terms and conditions of such parties and websites. This Privacy Policy only applies to our Services.</span></p>
                                <h2><b>Changes to our Privacy Policy</b></h2>
                                <p><span style={{ fontWeight: 400 }}>Subject to applicable law, we may revise this Privacy Policy at any time and in our sole discretion. When we revise this Privacy Policy, we will post the revised version via our Services and will update the date at the top of this Privacy Policy. The revised Privacy Policy will be effective upon posting via our Services, unless otherwise set forth therein or as otherwise required by applicable law. You are free to decide whether or not to accept a revised version of this Privacy Policy, but accepting this Privacy Policy, as revised, is required for you to continue accessing or using our Services. If you do not agree to the terms of this Privacy Policy or any revised version of this Privacy Policy, your sole recourse is to terminate your access and use of our Services. Except as otherwise expressly stated by us, your access and use of our Services is subject to the version of this Privacy Policy in effect at the time of access or use.</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="meeting-img d-flex justify-content-center">
                        <img src={Meeting} alt="meeting" ></img>
                    </div>
                </section>
                {/* second section */}

                <section>
                    <div className="container d-flex lady-phone-section">
                        <div className=" d-flex align-items-center">
                            <div className="lady-phone-text">
                                <div className="lady-phone-line ">
                                    <h3>
                                        Have a question?
                                        Our team is happy
                                        to assist you
                                    </h3>

                                    <p className="">
                                        Ask about Lucred products, pricing, implementation,
                                        or anthing else. Our highly trained reps are
                                        standing by, ready to help.
                                    </p>
                                </div>
                                <div className="d-flex mt-4">
                                    <button className="mt-3">Contact Us</button>
                                    <p className="ps-4">Or call  +234 7025457747</p>
                                </div>

                            </div>
                        </div>
                        <div className="lady-phone-img ">
                            <img src={LadyPhone} alt='lady-phone'></img>
                        </div>
                    </div>
                </section>

            </main >
            <Chat />
            <Footer />
        </>
    )
}

export default Privacy;