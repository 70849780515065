import React from "react";
import Footer from "../components/Footer";
import Navigation from "../components/navigation";
import Meeting from "../images/meeting.png"
import GirlOrange from "../images/girl-orange.png"
import Growth from "../images/growth.png"
import Chart from "../images/chart.png"
import Transaction from "../images/latest-transactions.png"
import Tunde from "../images/tunde-agboola.png"

import Facebook from "../images/facebook.png"
import Twitter from "../images/twitter.png"
import Youtube from "../images/youtube.png"
import Instagram from "../images/instagram.png"
import LadyPhone from "../images/lady-phone.png"
import Chat from "../components/Chat"
import { Link } from "react-router-dom";

import "../components/slick.css";
import "../components/slick-theme.css";

import Slider from "react-slick";

function About(){

    var settings = {
        dots: false,
        infinite: false,
        speed: 900,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };

    return(
        <>
            <Navigation />
            <main className="container">
            {/* first section */}
                <section>
                    <div className="meeting-text pt-5">
                        <h2>
                        🔒 Beyond Digital Credit Cards, Beyond Buy Now, Pay Later—Lucred Unleashes a Whole New Lending Experience.
                        </h2>
                        <Link to='/user-signup'><button className="my-4">Sign up</button></Link>
                    </div>
                    <div className="meeting-img d-flex justify-content-center">
                        <img src={ Meeting } alt="meeting" ></img>
                    </div>
                </section>
{/* second section */}
                <section>
                    <div className="d-flex girl-orange-wrapper">
                        <div className="girl-orange-text d-flex justify-content-center align-items-center">
                            <p>
                            Unlock a flexible line of credit in just 5 minutes with a few taps on your device. Say goodbye to lengthy paperwork and complicated processes!

                            </p>

                        </div>

                        <div className="girl-orange-img">
                            <img src={ GirlOrange } alt='girl-orange'></img>
                        </div>
                    </div>
                </section>
{/* third section */}

                <section>
                    <div className="container">
                        <div className="d-flex latest-transaction">
                            <div className="d-flex">
                                <div className="d-flex flex-column">
                                    <img src={ Transaction } alt='latest-transaction' className="img-one"></img>
                                    <img src={ Chart } alt="chart" className="img-two"></img>
                                </div>
                                <div>
                                    <img src={ Growth } alt="growth" className="img-three"></img>
                                </div>
                            </div>
                            <div className="latest-transaction-text d-flex ">
                                <p>
                                We use the APR model 
                                to calculate your interest 
                                and reward you for making 
                                timely payments on your 
                                preferred due date.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section>
                    <div className="container board-div mb-5">
                        <div className="py-5">
                            <h2>Management Team</h2>
                        </div>
                        <div className="board-container">
                            <Slider {...settings}>
                                <div className="card-container">
                                    <div>
                                        <img src={Tunde} style={{width: '250px'}} alt="" />
                                    </div>
                                    <div className="member-details">
                                        <h5>Tunde Onakoya</h5>
                                        <p>CEO</p>
                                        <div className="social-network-container">
                                            <img src={Facebook} className="pointer" style={{width: '15px'}} alt=""/>
                                            <img src={Twitter} className="pointer" style={{width: '15px'}} alt=""/>
                                            <img src={Youtube} className="pointer" style={{width: '15px'}} alt=""/>
                                            <img src={Instagram} className="pointer" style={{width: '15px'}} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-container">
                                    <div>
                                        <img src={Tunde} style={{width: '250px'}} alt="" />
                                    </div>
                                    <div className="member-details">
                                        <h5>Tunde Onakoya</h5>
                                        <p>CEO</p>
                                        <div className="social-network-container">
                                            <img src={Facebook} className="pointer" style={{width: '15px'}} alt=""/>
                                            <img src={Twitter} className="pointer" style={{width: '15px'}} alt=""/>
                                            <img src={Youtube} className="pointer" style={{width: '15px'}} alt=""/>
                                            <img src={Instagram} className="pointer" style={{width: '15px'}} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-container">
                                    <div>
                                        <img src={Tunde} style={{width: '250px'}} alt="" />
                                    </div>
                                    <div className="member-details">
                                        <h5>Tunde Onakoya</h5>
                                        <p>CEO</p>
                                        <div className="social-network-container">
                                            <img src={Facebook} className="pointer" style={{width: '15px'}} alt=""/>
                                            <img src={Twitter} className="pointer" style={{width: '15px'}} alt=""/>
                                            <img src={Youtube} className="pointer" style={{width: '15px'}} alt=""/>
                                            <img src={Instagram} className="pointer" style={{width: '15px'}} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-container">
                                    <div>
                                        <img src={Tunde} style={{width: '250px'}} alt="" />
                                    </div>
                                    <div className="member-details">
                                        <h5>Tunde Onakoya</h5>
                                        <p>CEO</p>
                                        <div className="social-network-container">
                                            <img src={Facebook} className="pointer" style={{width: '15px'}} alt=""/>
                                            <img src={Twitter} className="pointer" style={{width: '15px'}} alt=""/>
                                            <img src={Youtube} className="pointer" style={{width: '15px'}} alt=""/>
                                            <img src={Instagram} className="pointer" style={{width: '15px'}} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-container">
                                    <div>
                                        <img src={Tunde} style={{width: '250px'}} alt="" />
                                    </div>
                                    <div className="member-details">
                                        <h5>Tunde Onakoya</h5>
                                        <p>CEO</p>
                                        <div className="social-network-container">
                                            <img src={Facebook} className="pointer" style={{width: '15px'}} alt=""/>
                                            <img src={Twitter} className="pointer" style={{width: '15px'}} alt=""/>
                                            <img src={Youtube} className="pointer" style={{width: '15px'}} alt=""/>
                                            <img src={Instagram} className="pointer" style={{width: '15px'}} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-container">
                                    <div>
                                        <img src={Tunde} style={{width: '250px'}} alt="" />
                                    </div>
                                    <div className="member-details">
                                        <h5>Tunde Onakoya</h5>
                                        <p>CEO</p>
                                        <div className="social-network-container">
                                            <img src={Facebook} className="pointer" style={{width: '15px'}} alt=""/>
                                            <img src={Twitter} className="pointer" style={{width: '15px'}} alt=""/>
                                            <img src={Youtube} className="pointer" style={{width: '15px'}} alt=""/>
                                            <img src={Instagram} className="pointer" style={{width: '15px'}} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </section> */}

                <section>
                    <div className="container d-flex lady-phone-section">
                        <div className=" d-flex align-items-center">
                            <div className="lady-phone-text">
                                <div className="lady-phone-line ">
                                    <h3>
                                        Have a question?
                                        Our team is happy
                                        to assist you
                                    </h3>

                                    <p className="">
                                        Ask about Lucred products, pricing, implementation,
                                        or anything else. Our highly trained reps are 
                                        standing by, ready to help.
                                    </p>
                                </div>
                                <div className="d-flex contact-container mt-4">
                                    <button className="mt-3">Contact Us</button>
                                    <p className="ps-4">Or call  +234 7025457747</p>
                                </div>
                                
                            </div>
                        </div>
                        <div className="lady-phone-img ">
                            <img src={ LadyPhone } alt='lady-phone'></img>
                        </div>
                    </div>
                </section>
                
            </main>
            <Chat props="Chat with us" />
            <Footer />
        </>
    )
}

export default About;