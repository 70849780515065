import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Lucred from '../images/lucred-head.png'
import { Link, NavLink } from 'react-router-dom'



function Navigation(){
  
  
    return(
    <Navbar bg="light" expand="lg" className=" py-4">
        <Container >
            <Navbar.Brand href="/" className="nav-ext"><img className="logo" src={Lucred} alt="lucred"></img></Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
            <Nav
                className=" nav-content me-auto my-2 my-lg-0"
                style={{ maxHeight: '100px' }}
                navbarScroll
            >
                <NavLink to='/' className="navlink px-3" activeClassName="active " style={{ }}>Home</NavLink>
                <NavLink to='/merchant' className="navlink px-3" activeClassName="active " style={{ textDecoration: 'none'}}>Merchant</NavLink>
                <NavLink to='/partner' className="navlink px-3" activeClassName="active " style={{ textDecoration: 'none'}}>Partner</NavLink>
                <NavLink to='/about' className="navlink px-3" activeClassName="active " style={{ textDecoration: 'none'}}>About</NavLink>
                <NavLink to='/blog' className="navlink px-3" activeClassName="active " style={{ textDecoration: 'none'}}>Blog</NavLink>
            </Nav>
            <a href="https://onelink.to/nhzvzw"> <button className="nav-button">Count me in</button> </a>
            </Navbar.Collapse>
        </Container>
    </Navbar>
    )
}

export default Navigation;