import React from "react";
import Navigation from "../components/navigation";
import Footer from "../components/Footer";
import CompanyEfficiency from "../images/company-efficiency.png"
import DevEcoDcaf from "../images/dev-eco-dcaf.png"
import ArrowRight from "../icons/arrow-right.svg"
import RightLeftArrowCircle from "../icons/right-left-arrow-circle.svg"
import PeopleSmile from "../images/people-smile.png"
import GoogleWordXcel from "../images/google-word-xcel.png"
import BoySmile from "../images/boy-smile.png"
import Tick from "../icons/tick.svg"
import HandCard from "../images/hand-card.png"
import ChartBoard from '../images/partner-chart-board.png'
import Service from "../components/Service"
import Chat from "../components/Chat";
import { Link } from "react-router-dom";


function Partner ({ props }){
    return(
        <>
            <Navigation />
            <main className="container">
                <section>
                    <div className="d-flex justify-content-between company-demo-header">
                        <div className="pb-5">
                            <div className="company-demo-text">
                                <h2>
                                    Financial Wellness 
                                    at your Workplace 
                                </h2>
                                <p>
                                    Offer your employees credit and salary advance 
                                    that gives them access to upto 200% 
                                    of their salary, at no extra 
                                    cost to you!
                                </p>

                                <Link to='/request-demo'><button>Request a Demo</button> </Link>
                                <div className="d-flex py-3">
                                     <span>Recommend a Company </span>
                                    <img src={ArrowRight} alt="arrow-right" className="ps-1"></img>
                                </div>
                                <div className="provide-div d-flex justify-content-between py-3 mt-4 company-demo-second-text ">
                                    <p>We provide our services to many 
                                        worldwide leading  companies
                                    </p>
                                    <img src={RightLeftArrowCircle} alt="arrows" className="mt-3"></img>
                                </div>
                            </div>
                                <img src={DevEcoDcaf} alt="devecodcaf" className="not-show dev-eco-dcaf"></img>
                            </div>
                            <div className="company-efficiency">
                                <img src={CompanyEfficiency}></img>
                            </div>
                        </div>
                </section>


                <section>
                    <div className="container invest-wrapper">
                        <div className="invest-header py-5">
                            <h2>Secure Your Team Members Financial Future</h2>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="invest-img">
                                <img src={PeopleSmile} alt="people-smile"></img>
                            </div>
                            <div className="invest-text">
                                <div>
                                    <h3>Unlock Limitless Benefits</h3>
                                    <p>Invest in your staff without extra costs. This amazing incentive sets you apart, outshining all others. Empower your employees like never before.<br></br>
                                         <br></br>
                                         <br></br>
                                         .</p>
                                </div>
                                <div>
                                    <h3>Instant Relief</h3>
                                    <p>No more cash constraints in emergencies. Access a percentage of your salary pre-payday to handle unexpected expenses with ease. Financial security at your fingertips.<br></br>
                                         <br></br>
                                         </p>
                                </div>
                                <div>
                                    <h3>Elevate Employee Happiness</h3>
                                    <p>Coveted credit cards empower staff to spend up to 150% of their salary. Attract new talent and retain loyal ones effortlessly. Keep your team content and committed.<br></br>
                                         <br></br>
                                         <br></br>
                                        </p>
                                </div>
                               <div>
                                    <h3>Build Credit Score</h3>
                                    <p>Team members automatically build their credit history when they use Lucred. we report their credit activities to credit bureaus, therefore making our customers qualify for more financial benefits <br></br>
                                         <br></br>
                                         <br></br>
                                        </p>
                                </div> 
                                <div className="d-flex"> 
                                   <Link><button >Request a Demo </button></Link>
                                </div>  
                            </div>
                        </div>

                    </div>
                </section>


                <section>
                    <div className="integrate-wrapper d-flex">
                        <div className="google-work">
                            <img src={GoogleWordXcel} alt="google-word-xcel"></img>
                        </div>
                        <div className="integrate-text">
                            <h3>Integrates with your HR & Payroll Software</h3>
                            <p>Import your employee data from any existing HR <br></br>
                                software, or upload a CSV manually to enroll your <br></br>
                                employees for Lucred B2B. That's all it takes to <br></br>
                                get your employees started.</p>
                          <Link to='/request-demo'> <button>Request a Demo</button></Link>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="d-flex justify-content-between get-started-div">
                        <div className="d-flex flex-column justify-content-center">
                            <h4>What are you waiting for? <br></br>
                            Get started now!</h4>
                            <p>Sign up your company and onboard your staff .</p>
                            <p>Employees can apply for a credit line immediately.</p>
                            <p>Sit back while Lucred handles the payroll and credit deployment.</p>

                        </div>
                        <div>
                            <img src={BoySmile} alt="boy-smile"></img>
                        </div>
                    </div>
                </section>


                <section className="d-flex justify-content-center">
                
                    <div className="free-account d-flex justify-content-between">
                        <div className="mt-5 ms-4">
                            <div>
                                <h5>Free Account</h5>
                                <h3 className="py-2">$0</h3>
                                <span><p>Free forever for Organizations</p></span>
                                <p>Employees pay a small monthly <br></br>
                                    service charge on their credit.<br></br>
                                    </p>
                            </div>
                            <div>
                                <div className="d-flex ">
                                    <img src={Tick} alt='tick'></img>
                                    <p className="mt-3 ms-2">Customer Support</p>
                                </div>
                                <div className="d-flex">
                                    <img src={Tick} alt='tick'></img>
                                    <p className="mt-3 ms-2">Premium Benefit</p>
                                </div>
                                <div className="d-flex">
                                    <img src={Tick} alt='tick'></img>
                                    <p className="mt-3 ms-2">Financial Reports / Analytics </p>
                                </div>
                            </div>
                        </div>
                        <div className="hand-card">
                            <img src={HandCard} alt='habd-card'></img>
                        </div>
                    </div>
                </section>


                <section>
                    <div className="text-center chart-board">
                        <img src={ChartBoard} alt="chart-board" className="my-2"></img>
                        <h3 className="not-show py-5">Retain your employees and invest in your team <br></br>
                            wellbeing by offering them advance in-house.</h3>
                       <Link to='/request-demo'> <button className="not-show">Request a Demo For Your Company</button></Link>
                        
                    </div>
                </section>
            </main>
            <Service />
            <Chat props="Chat with us" />
            <Footer />
        </>
    )
}

export default Partner;