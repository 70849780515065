import React from "react";
import Footer from "../components/Footer";
import Navigation from "../components/navigation";
import Meeting from "../images/meeting.png"
import GirlOrange from "../images/girl-orange.png"
import Growth from "../images/growth.png"
import Chart from "../images/chart.png"
import Transaction from "../images/latest-transactions.png"
import Tunde from "../images/tunde-agboola.png"

import Facebook from "../images/facebook.png"
import Twitter from "../images/twitter.png"
import Youtube from "../images/youtube.png"
import Instagram from "../images/instagram.png"
import LadyPhone from "../images/lady-phone.png"
import Chat from "../components/Chat"
import { Link } from "react-router-dom";

function Terms() {
    return (
        <>
            <Navigation />
            <main className="container">
                {/* first section */}
                <section>
                    <div class="wpb_wrapper">
                        <div class="vc_row wpb_row vc_row-fluid vc_row-bg-position-top"></div>
                        <div class="wpb_column vc_column_container vc_col-sm-12">
                            <div class="vc_column-inner hide-on-mobile">
                                <div class="wpb_wrapper"></div>
                            </div>
                        </div>
                        <div class="wpb_text_column wpb_content_element">
                            <div class="wpb_wrapper">

                                <h1>Terms of Use</h1>

                                <h2>1. Introduction</h2>
                                <p>These Terms of Use (these "Terms") represent an agreement between you and Lucred Technology Limited ("Lucred") and contain the terms and conditions governing your use of and access to our website at <a href="http://www.lucred.co">www.lucred.co</a> and all affiliated websites including software applications (Software) owned and operated by Lucred (collectively referred to as the "Website") and our products, services, and applications (together with the Website, referred to as the "Services"). "User", "You" and "Your" mean the person who uses or accesses the Services. "We", "Us" and "Our" mean Lucred and its successors, affiliates, and assignees. As used in these Terms, "Lucred Account" means the account you have with us for the Services.</p>

                                <h2>1.2 Trademarks</h2>
                                <p>"Lucred", "the Logo on this Website and all other designs or impressions" are registered brands and trademarks of Lucred Technology Limited, a company duly registered under the laws of the Federal Republic of Nigeria;</p>

                                <h2>1.3 Acceptance of Terms</h2>
                                <p>Your use of and access to the Services are subject at all times to these Terms, our Frequently Asked Questions ("FAQs") (accessible through this link [.]) and our Privacy Policy (accessible through this link [.]). Please read these Terms, our FAQs, and our Privacy Policy carefully. By using or accessing the Services, you represent that you have read and understand these Terms, our FAQs, Credit Card Agreement (accessible through this link [.]), and our Privacy Policy, and you agree to be bound by these Terms, our FAQs, Credit Card Agreement, and our Privacy Policy. If you do not agree to all the terms and conditions of these Terms, our FAQs, Credit Card Agreement, and our Privacy Policy, please DO NOT use or access the Services.</p>

                                <h2>1.4 Changes to Terms</h2>
                                <p>Lucred reserves the right to review and change any of the Terms by updating this page at its sole discretion at any time and without your consent or permission. Any changes to the Terms will take effect immediately from the date of their publication. Your access and use of the Software/Website after Lucred makes any changes constitute your acceptance of any such changes, and you will be bound by those changes. If you do not agree to any changes, you must cease usage of the Software and any of its associated products or Services immediately.</p>

                                <h2>2. Intellectual Property Rights</h2>
                                <p>Unless otherwise stated, we and our licensors own, retain all right, title, and interest in and to the Services and any associated patents, trademarks, copyrights, mask work rights, trade secrets, and other intellectual property rights.</p>

                                <p>For the avoidance of doubt, Lucred does not grant you any licenses, express or implied, to our intellectual property or the intellectual property of our licensors, except as expressly stated in these Terms.</p>

                                <h2>3. Restrictions</h2>
                                <p>Except as expressly provided by these Terms or with our prior written consent, you shall not:</p>

                                <ul>
                                    <li>Use, modify, disassemble, decompile, reverse engineer, reproduce, distribute, rent, sell, license, publish, display, download, transmit, or otherwise exploit any content on our Website in any form by any means;</li>
                                    <li>Scrape or copy information through any means including crawlers, browser plugins and add-ons, and any other technology or manual work;</li>
                                    <li>Utilize any device, software, or routine that will interfere or attempt to interfere with the functionality of the Services;</li>
                                    <li>Use any portion of the Services to provide, or incorporate any portion of the Services into, any product or service provided to a third party;</li>
                                    <li>Reverse engineer, decompile, disassemble, or otherwise seek to obtain the source code of the Services;</li>
                                    <li>Remove or obscure any proprietary or other notices contained in the Services;</li>
                                    <li>Use the Services in any way that is illegal, harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable, as we may determine in our sole discretion;</li>
                                    <li>Attempt, in any manner, to obtain the username, password, account, or other security information from any other user of the Services;</li>
                                    <li>Violate the security of any computer network or crack any passwords or security encryption codes;</li>
                                    <li>Run any form of auto-responder or "spam," any processes that run or are activated while you are not logged in to access the Services.</li>
                                </ul>

                                <p>Any content uploaded by a User must not be illegal, threatening, obscene, racist, defamatory, libelous, pornographic, infringing of intellectual property rights, promoting of illegal activity or harm to groups and/or individuals, invasive of privacy, purposely false or otherwise injurious to third parties, or objectionable, and must not consist of or contain software, computer viruses, commercial solicitation, political campaigning, chain letters, mass mailings, or any form of spam.</p>

                                <h2>4. User Obligations</h2>

                                <h3>4.1 Eligibility</h3>
                                <p>You must be 18 years old or older to access or use our Website or mobile applications. Any access to or use of our Website or our Services by anyone under 18 is unauthorized, unlicensed, and in violation of these Terms of Use. By accessing or using Lucred, you represent and warrant that you are 18 or older.</p>
                                <p>You must be a Nigerian citizen or a legal Nigerian resident and have a bank account with a Nigerian financial institution.</p>
                                <p>You must provide all information requested by us, such as your name, email address, valid home address, mobile device number, bank verification number (BVN), online credentials for your bank account, and such other information as we may request from time to time.</p>
                                <p>Notwithstanding your provision of the above, the use of the Lucred platform or provision of credit to you is totally at the discretion of Lucred.</p>

                                <h3>4.2 Access</h3>
                                <p>In order to access certain features of our Services, you must register to create a Lucred Account. When you register, you will be asked to choose a password, which you will be required to use to access your Lucred Account. You are responsible for safeguarding your password and other Lucred Account information. You agree not to disclose your password to any third party and will notify Lucred immediately if your password is lost or stolen or if you suspect any unauthorized use of your Lucred Account. As a User, you agree that you shall be solely responsible for any activities or actions under your Lucred Account, whether or not you have authorized such activities or actions.</p>

                                <p>You represent and warrant that all User Information you provide us from time to time is truthful, accurate, current, and complete, and you agree not to misrepresent your identity or your User Information. You agree to promptly notify us of changes to your User Information by updating your Lucred Account on the Website; provided, however, that you must notify us at least 3 (three) working days before any changes to your bank account information, including, but not limited to, the closure of your bank account for any reason by emailing hello@lucred.co or by updating your Lucred Account via the Website or mobile application. If we approve your registration, you will be authorized to use the Services, subject to these Terms.</p>

                                <p>You may only use the Services for your own personal, non-commercial use and not on behalf of or for the benefit of any third party. Your use of the Services must comply with all applicable laws. If your use of the Services is prohibited by applicable law, then you are not authorized to use the Services. We shall not be responsible if you use the Services in any manner that violates any applicable laws. Lucred reserves the right to immediately terminate your account if found to violate any laws.</p>

                                <p>You further acknowledge and agree that where items or products are purchased using the credit provided by Lucred, the ownership rights in such items or products are vested in Lucred pending your repayment of the credit advanced or provided by Lucred to you for the purchase of the product or item. For the avoidance of doubt, the foregoing provision shall apply notwithstanding that such product or item has been delivered to you by the relevant merchant and you agree and acknowledge that Lucred or any of its third-party service providers or agents may at any time recover the product or item from you.</p>

                                <h3>4.3 Compliance</h3>
                                <p>For our compliance purposes and in order to provide the Services, you hereby authorize us to, directly or through a third-party, obtain, verify, and file information and documentation that helps us verify your identity and bank account information including your BVN. When you register for the Services and from time to time thereafter, we may require you to provide and/or confirm information and documentation that will allow us to identify you, such as but not limited to:</p>
                                <ul>
                                    <li>A copy of your government-issued photo ID, such as an international passport, national identity card, voter’s card, or driver’s license;</li>
                                    <li>A copy of a utility bill, bank statement, affidavit, or other bill, dated within 2 (two) months of our request, with your name and Nigerian residential or work address on it;</li>
                                    <li>BVN;</li>
                                    <li>Such other information and documentation that we may require from time to time.</li>
                                </ul>

                                <p>By using the Services and providing User Information to us, you automatically authorize us to obtain, directly or indirectly through our third-party service providers and without any time limit or the requirement to pay any fees, information about you and your bank accounts from any financial institution holding your bank account and other third-party websites and databases as necessary to provide the Services to you. For purposes of such authorization, you hereby grant Lucred and our third-party service providers a limited power of attorney, and you hereby appoint Lucred and our third-party service providers as your true and lawful attorney-in-fact and agent, with full power of substitution and resubstitution, for you and in your name, place, and stead, in any and all capacities, to access third-party websites, servers, and documents; retrieve information; and use your User Information, all as described above, with the full power and authority to do and perform each and every act and thing requisite and necessary to be done in connection with such activities, as fully to all intents and purposes as you might or could do in person. YOU ACKNOWLEDGE AND AGREE THAT WHEN Lucred OR OUR THIRD-PARTY SERVICE PROVIDERS ACCESS AND RETRIEVE INFORMATION FROM SUCH THIRD-PARTY WEBSITES, Lucred AND OUR THIRD-PARTY SERVICE PROVIDERS ARE ACTING AS YOUR AGENT, AND NOT THE AGENT OR ON BEHALF OF THE THIRD PARTY. You agree that other third parties shall be entitled to rely on the foregoing authorization, agency, and power of attorney granted by you. We make no effort to review information obtained from the financial institution holding your bank account and other third-party websites and databases for any purpose, including, but not limited to, accuracy, legality, or non-infringement. As between Lucred and our third-party service providers, Lucred owns your confidential User Information.</p>

                                <h3>4.4 Service Communications</h3>
                                <p>By creating a Lucred Account, you automatically sign up for various types of alerts via e-mail and mobile notifications. To the extent permitted by applicable law, you consent to use electronic signatures and to electronically receive all records, notices, statements, communications, and other items for all Services provided to you under these Terms and in connection with your relationship with us (collectively referred to as Communications). Anyone with access to your e-mail or mobile device will be able to view these alerts. By accepting and agreeing to these Terms electronically, you represent that: (a) you have read and understand this consent to use electronic signatures and to receive Communications electronically; and (b) your consent will remain in effect until you withdraw your consent as specified below.</p>

                                <p>Your consent to receive electronic communications will remain in effect until you withdraw it. You may withdraw your consent to receive further communications at any time by contacting us at hello@lucred.co. If you withdraw your consent to receive communications electronically, we will close your Lucred Account and you will no longer be able to use your Lucred Account or the Services, except as expressly provided in these Terms. Any withdrawal of your consent to receive communications electronically will be effective only after we have a reasonable period of time to process your withdrawal from your Lucred Savings Account or after payment of all monies due on your Lucred Credit Account. Please note that withdrawal of your consent to receive communications electronically will not apply to communications electronically provided by us to you before the withdrawal of your consent becomes effective.</p>

                                <p>In order to ensure that we are able to provide Communications to you electronically, you must notify us of any change in your email address and your mobile device number by updating your Lucred Account on the Website.</p>

                                <h3>4.5 Credit Terms</h3>
                                <p>You agree and warrant that you shall repay all monies due under your Lucred Credit Account as and when due. In the event of a default, we reserve the right to suspend your use of our Services and apply any monies in your Lucred Wallet Account (where applicable) or your bank account to defray your outstanding credit. This is without prejudice to our right to seek compensatory damages as permitted under applicable law should the need arise.</p>

                                <p>Furthermore, you agree and authorize Lucred to offset any outstanding amount you are in default of by making deductions from any of your accounts domiciled with any commercial or microfinance bank in Nigeria of the outstanding sum, whether the account is in Naira or other currency, regardless of whether such accounts are linked with your Lucred account in line with Central Bank of Nigeria policy on loan settlements.</p>

                                <p>You further acknowledge and agree that in the event you are in default of any of your repayment obligations to Lucred, Lucred, in addition to any remedies under the law is entitled to:</p>
                                <ul>
                                    <li>Retain or seize or refuse to deliver any products you have purchased using the Lucred platform or any other product of Lucred;</li>
                                    <li>Disclose your User Information with third-party credit bureau agencies duly licensed under applicable laws;</li>
                                    <li>Disclose your User Information with third-party debt collector agents for the purpose of recovering the total amount due to Lucred.</li>
                                </ul>

                                <h3>4.6 Repayment of Credit</h3>
                                <p>You agree and warrant that you shall repay all monies due under your Lucred Credit Account as and when due. In the event of a default, we reserve the right to suspend your use of our Services and apply any monies in your Lucred Wallet Account (where applicable) or your bank account to defray your outstanding credit. This is without prejudice to our right to seek compensatory damages as permitted under applicable law should the need arise.</p>

                                <p>Furthermore, you agree and authorize Lucred to offset any outstanding amount you are in default of by making deductions from any of your accounts domiciled with any commercial or microfinance bank in Nigeria of the outstanding sum, whether the account is in Naira or other currency, regardless of whether such accounts are linked with your Lucred account in line with Central Bank of Nigeria policy on loan settlements.</p>

                                <p>You further acknowledge and agree that in the event you are in default of any of your repayment obligations to Lucred, Lucred, in addition to any remedies under the law is entitled to:</p>
                                <ul>
                                    <li>Retain or seize or refuse to deliver any products you have purchased using the Lucred platform or any other product of Lucred;</li>
                                    <li>Disclose your User Information with third-party credit bureau agencies duly licensed under applicable laws;</li>
                                    <li>Disclose your User Information with third-party debt collector agents for the purpose of recovering the total amount due to Lucred.</li>
                                </ul>

                                <h2>5. Warranty Disclaimer</h2>

                                <h3>5.1</h3>
                                <p>
                                    TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAWS AND EXCEPT AS OTHERWISE EXPRESSLY PROVIDED IN THESE TERMS, YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES AND ALL INFORMATION, PRODUCTS, AND OTHER CONTENT (INCLUDING THAT OF THIRD PARTIES) INCLUDED IN OR ACCESSIBLE FROM THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE AND OUR THIRD-PARTY SERVICE PROVIDERS EXPRESSLY DISCLAIM ANY AND ALL CONDITIONS, REPRESENTATIONS, AND WARRANTIES OF ANY KIND AS TO THE SERVICES AND ALL INFORMATION, PRODUCTS, AND OTHER CONTENT (INCLUDING THAT OF THIRD PARTIES) INCLUDED IN OR ACCESSIBLE FROM THE SERVICES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
                                </p>

                                <h3>5.2</h3>
                                <p>
                                    Lucred EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY, RELATING TO Lucred’S WEBSITE, CONTENT AND/OR USER INFORMATION, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OF PROPRIETARY RIGHTS, COURSE OF DEALING OR COURSE OF PERFORMANCE.
                                </p>

                                <h3>5.3</h3>
                                <p>
                                    WITHOUT LIMITING THE FOREGOING, WE AND OUR THIRD-PARTY SERVICE PROVIDERS MAKE NO WARRANTY REGARDING THE PERFORMANCE OF OR ACCURACY, QUALITY, CURRENCY, COMPLETENESS, OR USEFULNESS OF ANY INFORMATION PROVIDED BY Lucred ON ITS WEBSITE INCLUDING BUT NOT LIMITED TO INFORMATION OBTAINED THROUGH SOCIAL MEDIA. SPECIFICALLY, Lucred MAKES NO WARRANTY THAT (A) THE SERVICES WILL MEET YOUR REQUIREMENTS, (B) THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (C) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE, (D) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICES WILL MEET YOUR EXPECTATIONS, OR (E) ANY ERRORS IN THE SERVICES WILL BE CORRECTED. NO ORAL OR WRITTEN INFORMATION OR ADVICE OBTAINED BY YOU FROM US OR OUR THIRD-PARTY SERVICE PROVIDERS THROUGH OR FROM THE SERVICES WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS.
                                </p>

                                <h2>6. Limitation Of Liability</h2>
                                <p>
                                    TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE THAT Lucred, ITS AFFILIATES AND THEIR RESPECTIVE EMPLOYEES, OFFICERS, DIRECTORS, AGENTS, AND THIRD-PARTY SERVICE PROVIDERS WILL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR (A) THE PERFORMANCE OF THE SERVICES OR THE INABILITY TO USE THE SERVICES; (B) ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR EXEMPLARY DAMAGES, WHETHER BASED IN CONTRACT, TORT, OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES, EVEN IF SUCH PERSONS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, WHICH ARISE OUT OF OR ARE IN ANY WAY CONNECTED WITH THESE TERMS, OR THE SERVICES; (C) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF N30,000.00 (THIRTY THOUSAND NAIRA); OR (D) ANY EVENT BEYOND OUR REASONABLE CONTROL.
                                </p>

                                <h2>7. Third Party Services</h2>

                                <h3>7.1</h3>
                                <p>
                                    You understand that Lucred may engage other third-party service providers in delivering its services to you, and you agree to be responsible and bear such third-party transactional charges and fees that may be incurred in providing the service to you. You also grant Lucred the right to recover any amount previously incurred on your behalf in engaging with such third-party providers.
                                </p>

                                <h3>7.2</h3>
                                <p>
                                    The Services may contain links or connections to third-party websites or services that are not owned or operated by us or our third-party service providers or licensors. We provide such links and connections for your reference only. We do not control such third-party websites or services and are not responsible for their availability or content. Our inclusion of such links and connections does not imply our endorsement of such third-party websites or services or any association with their owners or operators. We assume no liability whatsoever for any such third-party websites or services or any content, features, products, or practices of such third-party websites or services. Your access and use of such third-party websites and services are subject to applicable third-party terms and conditions and privacy policies. We encourage you to read the terms and conditions and privacy policy of each third-party website or service that you visit.
                                </p>

                                <h2>8. Indemnity</h2>

                                <h3>8.1</h3>
                                <p>
                                    You agree to indemnify Lucred and its officers, directors, employees, sublicensees, agents, affiliates, successors, and assigns from and against any and all claims, damages, liabilities, losses, settlements, costs, and expenses (including, without limitation, lawyers’ fees and court costs) which arise out of or relate to your use of the Software, Website, and/or Services, including but not limited to:
                                </p>
                                <ul>
                                    <li>any misuse of the Software or Services by you, including and without limitation, any dishonest, inaccurate, incomplete, fraudulent, negligent, wrongful, unlawful, or criminal act or omission by you;</li>
                                    <li>any act or omission (including negligent acts or omissions) of the user in the performance or purported performance of any Services and your breach of the Terms;</li>
                                    <li>the use of any intellectual property created in respect of the Services not contemplated by the Terms or as a consequence of a breach of the Terms; or</li>
                                    <li>any activity which you engage on or through Lucred.</li>
                                </ul>

                                <h2>9. Entire Agreement</h2>
                                <p>
                                    These Terms of Use, together with our Privacy Policy, Credit Card Agreement, and FAQs, constitute the entire agreement between you and Lucred with respect to the Services and supersedes all prior understandings, arrangements, or agreements, whether written or oral, regarding the Services.
                                </p>

                                <h2>10. Variation</h2>

                                <h3>10.1</h3>
                                <p>
                                    Lucred may add to or amend these Terms at any time, at our sole discretion, without providing notice to you. We reserve the right, subject to applicable law, to deliver to you any notice of changes to these Terms or the addition of new terms by posting an updated version of these Terms on the Website or delivering notice thereof to you electronically.
                                </p>

                                <h3>10.2</h3>
                                <p>
                                    You are free to decide whether or not to accept a revised version of these Terms, but accepting these Terms, as revised, is required for you to continue accessing or using the Services. If you do not agree to these Terms or any revised version of these Terms, your sole recourse is to terminate your access or use of the Services. Except as otherwise expressly stated by us, your access and use of the Services are subject to, and constitute your acceptance of, the version of these Terms in effect at the time of your access or use.
                                </p>

                                <h3>10.3</h3>
                                <p>
                                    We encourage you to check these Terms from time to time in order for you to be abreast with any modifications thereto.
                                </p>

                                <h2>11. Dispute Resolution</h2>
                                <p>
                                    Any dispute relating to or arising out of these Terms and/or the use of the Services shall be resolved amicably by mutual consultation. Where we and you are unable to resolve any dispute amicably within 21 (twenty-one) days of the commencement of such mutual consultation, the dispute shall be submitted to and determined by any court of competent jurisdiction in Nigeria.
                                </p>

                                <h2>12. Governing Law</h2>
                                <p>
                                    These Terms and the provision of the Services shall be governed by and construed in accordance with the laws of the Federal Republic of Nigeria.
                                </p>

                                <h2>13. Termination</h2>
                                <p>
                                    Each Party may end this agreement at any time. You may request termination of your Lucred Account at any time and for any reason. Please note that deleting our App or not logging on to our website does not signify termination of your Lucred Account. The only way to terminate the account is by sending an email with the instruction to terminate your account to <a href="mailto:hello@lucred.co">hello@lucred.co</a>. We may terminate or suspend your access to our Services, in our sole discretion, at any time for any reason without notice to you, if we believe, in our sole discretion, that a violation of these Terms of Use has occurred.
                                </p>

                                <h2>14. General Terms</h2>

                                <h3>14.1</h3>
                                <p>
                                    You may not transfer, assign, or delegate these Terms or your rights or obligations hereunder or your Lucred Account in any way (by operation of law or otherwise) without our prior written consent. To the extent that you attempt to do so, such transfer, assignment or delegation shall be treated as null and void. You agree that we may freely assign or transfer these Terms and our rights and obligations hereunder to any third-party service provider or third party without notice or consent. These Terms will inure to the benefit of our successors, assigns, and licensees.
                                </p>

                                <h3>14.2</h3>
                                <p>
                                    Our failure to act with respect to a breach of these Terms by you or others does not waive our right to act with respect to that breach or subsequent or similar breaches.
                                </p>

                                <h3>14.3</h3>
                                <p>
                                    You agree that no joint venture, partnership, employment, or agency relationship exists between you and us as a result of these Terms or your use of the Services.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* second section */}

                <section>
                    <div className="container d-flex lady-phone-section">
                        <div className=" d-flex align-items-center">
                            <div className="lady-phone-text">
                                <div className="lady-phone-line ">
                                    <h3>
                                        Have a question?
                                        Our team is happy
                                        to assist you
                                    </h3>

                                    <p className="">
                                        Ask about Lucred products, pricing, implementation,
                                        or anthing else. Our highly trained reps are
                                        standing by, ready to help.
                                    </p>
                                </div>
                                <div className="d-flex mt-4">
                                    <button className="mt-3">Contact Us</button>
                                    <p className="ps-4">Or call  +234 7025457747</p>
                                </div>

                            </div>
                        </div>
                        <div className="lady-phone-img ">
                            <img src={LadyPhone} alt='lady-phone'></img>
                        </div>
                    </div>
                </section>

            </main>
            <Chat />
            <Footer />
        </>
    )
}

export default Terms;