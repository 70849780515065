import React from "react";
import Navigation from "../components/navigation";
import Footer from "../components/Footer"
import BlogLday from '../images/blog-lady.png'
import Chat from "../components/Chat"
import ThreeBoys from "../images/three-boys.png"
import LadiesCoffee from "../images/ladies-laptop-coffee.png"
import TablePeople from "../images/table-people.png"
import Calender from "../icons/calender.svg"
import View from "../icons/view.svg"
import Time from "../icons/time.svg"


function Blog() {
    return (<di>
        <Navigation />
        <main>
            <div className="container">
                <section>
                    <div className="blog-intro">
                        <div className="blog-intro-header">
                            <h3>Introduction to Lucred</h3>
                        </div>
                        <div className="blog-intro-text">
                            <div className="car">
                                <div className="d-flex blog-title-container">
                                    <div className="blog-text-wrapper">
                                        <p>Introducing Lucred: Your Revolving Credit Line for Unparalleled Financial Freedom!

                                        </p>
                                        <div className="d-flex justify-content-end me-5">
                                            <button>Read more</button>
                                        </div>
                                    </div>
                                    <div className="blog-lady-img">
                                        <img src={BlogLday} alt='blog-lady'></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>

                    <div>
                        <div className="latest-header">
                            <h3>Latest News</h3>
                        </div>
                        <div className="row d-flex mt-5 details">
                            <div className="grid col-12 col-md-4 col-sm-4">
                                <div className="latest-news-card">
                                    <div className="latest-news-img">
                                        <img src={TablePeople} alt='table-people'></img>
                                    </div>
                                    <div className="latest-news-text">
                                        <h6>Why Lucred was created</h6>
                                        <p>Why Lucred was created So Jennifer started a <br></br>
                                            gadget retailing business in the premier city of <br></br>
                                            Ibadan around July 2021. As the business started <br></br>
                                            to grow, a lot of customers would come in and <br></br>
                                            request if they can buy an item and pay later <br></br>
                                            or pay in batches. …</p>
                                    </div>
                                    <div className="d-flex latest-news-icon">
                                        <div className="d-flex pe-3">
                                            <img src={View} alt='view' className="mb-2"></img>
                                            <p className="mt-2 ps-1">226</p>
                                        </div>
                                        <div className="d-flex pe-3">
                                            <img src={Time} alt='time' className="mb-2"></img>
                                            <p className="mt-2 ps-1">4 mins</p>
                                        </div>
                                        <div className="d-flex pe-3">
                                            <img src={Calender} alt='calender' className="mb-2"></img>
                                            <p className="mt-2 ps-1">July 28 2022</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid col-12 col-md-4 col-sm-4">
                                <div className="latest-news-card">
                                    <div className="latest-news-img">
                                        <img src={ThreeBoys} alt='three-boys'></img>
                                    </div>
                                    <div className="latest-news-text">
                                        <h6>5 Reasons to Anticipate
                                            Lucred in 2023</h6>
                                        <p>As we enter the new year of 2023, it is <br></br>
                                            important to stay up-to-date on the latest <br></br>
                                            payment solutions that can help individuals <br></br>
                                            and businesses make purchases more <br></br>
                                            affordably and conveniently. One payment <br></br>
                                            solution that is worth keeping an eye on is <br></br>
                                            Lucred, it offers a credi....</p>
                                    </div>
                                    <div className="d-flex latest-news-icon" >
                                        <div className="d-flex pe-3">
                                            <img src={View} alt='view' className="mb-2"></img>
                                            <p className="mt-2 ps-1">226</p>
                                        </div>
                                        <div className="d-flex pe-3">
                                            <img src={Time} alt='time' className="mb-2"></img>
                                            <p className="mt-2 ps-1">4 mins</p>
                                        </div>
                                        <div className="d-flex pe-3">
                                            <img src={Calender} alt='calender' className="mb-2"></img>
                                            <p className="mt-2 ps-1">July 28 2022</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid col-12 col-md-4 col-sm-4">
                                <div className="latest-news-card">
                                    <div className="latest-news-img">
                                        <img src={LadiesCoffee} alt='ladies-coffe'></img>
                                    </div>
                                    <div className="latest-news-text">
                                        <h6>Reasons to use Credit</h6>
                                        <p>Having access to credit is convenient and <br></br>
                                            makes budgeting easier. If properly used, <br></br>
                                            credits could just be your ticket to achieving <br></br>
                                            great financial well-being. Cash used to be king. <br></br>
                                            People paid for everyday purchases with cash <br></br>
                                            but carrying cash comes with its disadvantages <br></br>
                                            If you....</p>
                                    </div>
                                    <div className="d-flex latest-news-icon" >
                                        <div className="d-flex pe-3">
                                            <img src={View} alt='view' className="mb-2"></img>
                                            <p className="mt-2 ps-1">226</p>
                                        </div>
                                        <div className="d-flex pe-3">
                                            <img src={Time} alt='time' className="mb-2"></img>
                                            <p className="mt-2 ps-1">4 mins</p>
                                        </div>
                                        <div className="d-flex pe-3">
                                            <img src={Calender} alt='calender' className="mb-2"></img>
                                            <p className="mt-2 ps-1">July 28 2022</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row d-flex mt-5">
                            <div className="grid col-12 col-md-4 col-sm-4">
                                <div className="latest-news-card">
                                    <div className="latest-news-img">
                                        <img src={TablePeople} alt='table-people'></img>
                                    </div>
                                    <div className="latest-news-text">
                                        <h6>Why Lucred was created</h6>
                                        <p>Why Lucred was created So Jennifer started a <br></br>
                                            gadget retailing business in the premier city of <br></br>
                                            Ibadan around July 2021. As the business started <br></br>
                                            to grow, a lot of customers would come in and <br></br>
                                            request if they can buy an item and pay later <br></br>
                                            or pay in batches. …</p>
                                    </div>
                                    <div className="d-flex latest-news-icon">
                                        <div className="d-flex pe-3">
                                            <img src={View} alt='view' className="mb-2"></img>
                                            <p className="mt-2 ps-1">226</p>
                                        </div>
                                        <div className="d-flex pe-3">
                                            <img src={Time} alt='time' className="mb-2"></img>
                                            <p className="mt-2 ps-1">4 mins</p>
                                        </div>
                                        <div className="d-flex pe-3">
                                            <img src={Calender} alt='calender' className="mb-2"></img>
                                            <p className="mt-2 ps-1">July 28 2022</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid col-12 col-md-4 col-sm-4">
                                <div className="latest-news-card">
                                    <div className="latest-news-img">
                                        <img src={ThreeBoys} alt='three-boys'></img>
                                    </div>
                                    <div className="latest-news-text">
                                        <h6>5 Reasons to Anticipate
                                            Lucred in 2023</h6>
                                        <p>As we enter the new year of 2023, it is <br></br>
                                            important to stay up-to-date on the latest <br></br>
                                            payment solutions that can help individuals <br></br>
                                            and businesses make purchases more <br></br>
                                            affordably and conveniently. One payment <br></br>
                                            solution that is worth keeping an eye on is <br></br>
                                            Lucred, it offers a credi....</p>
                                    </div>
                                    <div className="d-flex latest-news-icon" >
                                        <div className="d-flex pe-3">
                                            <img src={View} alt='view' className="mb-2"></img>
                                            <p className="mt-2 ps-1">226</p>
                                        </div>
                                        <div className="d-flex pe-3">
                                            <img src={Time} alt='time' className="mb-2"></img>
                                            <p className="mt-2 ps-1">4 mins</p>
                                        </div>
                                        <div className="d-flex pe-3">
                                            <img src={Calender} alt='calender' className="mb-2"></img>
                                            <p className="mt-2 ps-1">July 28 2022</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid col-12 col-md-4 col-sm-4">
                                <div className="latest-news-card">
                                    <div className="latest-news-img">
                                        <img src={LadiesCoffee} alt='ladies-coffe'></img>
                                    </div>
                                    <div className="latest-news-text">
                                        <h6>Reasons to use Credit</h6>
                                        <p>Having access to credit is convenient and <br></br>
                                            makes budgeting easier. If properly used, <br></br>
                                            credits could just be your ticket to achieving <br></br>
                                            great financial well-being. Cash used to be king. <br></br>
                                            People paid for everyday purchases with cash <br></br>
                                            but carrying cash comes with its disadvantages <br></br>
                                            If you....</p>
                                    </div>
                                    <div className="d-flex latest-news-icon" >
                                        <div className="d-flex pe-3">
                                            <img src={View} alt='view' className="mb-2"></img>
                                            <p className="mt-2 ps-1">226</p>
                                        </div>
                                        <div className="d-flex pe-3">
                                            <img src={Time} alt='time' className="mb-2"></img>
                                            <p className="mt-2 ps-1">4 mins</p>
                                        </div>
                                        <div className="d-flex pe-3">
                                            <img src={Calender} alt='calender' className="mb-2"></img>
                                            <p className="mt-2 ps-1">July 28 2022</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="get-story-div d-flex flex-column align-items-center">
                        <div >
                            <h4>Get more stories</h4>
                        </div>
                        <form className="d-flex">
                            <div>
                                <input placeholder="    Enter your email"></input>
                            </div>
                            <div className="story-button">
                                <button>Subscribe</button>
                            </div>
                        </form>
                    </div>
                </section>

            </div>
        </main>
        <Chat props="Chat with us" />
        <Footer />
    </di>)
}

export default Blog;