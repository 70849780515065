import React, {useState} from "react";
import Wave from "../images/waving.png"
import Send from "../images/send.png"
import LadySmile from "../images/lady-smile.png"
import AddButton from "../images/add-button.png"

function Servive(){

    const  [toggleOne, setToggleOne] = useState(true)
    const  [toggleTwo, setToggleTwo] = useState(true)
    const  [toggleThree, setToggleThree] = useState(true)
    const  [toggleFour, setToggleFour] = useState(true)
    const  [toggleFive, setToggleFive] = useState(true)
    const  [toggleSix, setToggleSix] = useState(true)



    return(
        <div className="customer-service ">
            <div className="text-center">
                <h4>How can we help you?</h4>
                <p>You have questions, we have answers!</p>
            </div>
            <div className="customer-shadow ">
                <div className="lady-wrapper d-flex justify-content-between">
                    <div className="lady-smile-img">
                        <img src={LadySmile} alt="lady-smile"></img>
                    </div>
                    <div className="lady-smile-text d-flex flex-column justify-content-center">
                            <h3>Lucred</h3>
                            <p>helping you do more! <br></br> credit for everyone</p>
                    </div>
                </div>
                <div className="faq-wrapper">
                    <h3>FAQs</h3>
                    <div className="d-flex">



                        <div className="faq-img">
                            <div className="faq-img d-flex">
                            {toggleOne ?
                                    <div className="d-flex">
                                        <button onClick={()=> {setToggleOne(!toggleOne)}} className="text-center">+</button>
                                        
                                        <div className="ms-3">
                                        <h5>What is Lucred?</h5> 
                                        </div>
                                    </div>: 
                                    (
                                    <div className="d-flex">
                                                <button onClick={()=> {setToggleOne(!toggleOne)}} className="text-center">-</button>
                                                <div className="d-flex flex-column ms-3 ">
                                                    <h5>What is Lucred?</h5>
                                                    <p>Lucred is a digital credit platform that makes purchases affordable 
                                                        by offering you a shopping credit line that you can spend now and 
                                                        pay overtime. We exist to help you thrive by allowing buy the essential 
                                                        things you need! </p> 
                                                </div>
                                    </div>)}
                            </div>
                            



                            <div className="faq-img d-flex">
                                {toggleTwo ?
                                        <div className="d-flex">
                                            <button onClick={()=> {setToggleTwo(!toggleTwo)}} className="text-center">+</button>
                                            <div className="ms-3">
                                                <h5>Who can apply?</h5> 
                                            </div>
                                        </div>: 
                                        (
                                        <div className="d-flex">
                                                    <button onClick={()=> {setToggleTwo(!toggleTwo)}}>-</button>
                                                    <div className="d-flex flex-column ms-3">
                                                        <h5>Who can apply?</h5>
                                                        <p>Lucred was made for everybody! We offer Credit to business owners, salary earners, students and many more. </p> 
                                                    </div>
                                        </div>)}
                            </div>
                            
                            
                            
                            <div className=" d-flex">
                                {toggleThree ?
                                        <div className="d-flex">
                                            <button onClick={()=> {setToggleThree(!toggleThree)}} className="text-center">+</button>
                                            <div className="ms-3">
                                                <h5>What do i need to apply?</h5> 
                                            </div>
                                        </div>: 
                                        (
                                        <div className="d-flex">
                                                    <button onClick={()=> {setToggleThree(!toggleThree)}}>-</button>
                                                    <div className="d-flex flex-column ms-3">
                                                        <h5>What do i need to apply?</h5>
                                                        <p>All you need to apply is your phone number, BVN, and banking credentials 
                                                            and you get approved in 5 minutes. As a fintech company, we ensure that all 
                                                            your information is stored securely with bank-grade security and that sensitive 
                                                            information is encrypted and governed strictly by our privacy policy.</p>
                                                    </div>
                                        </div>)}    
                            </div>
                        
                        
                        </div>
                        <div>


                            <div className="faq-img d-flex">
                            
                                {toggleFour ?
                                    <div className="d-flex">
                                        <button onClick={()=> {setToggleFour(!toggleFour)}} className="">+</button>
                                        <div className="ms-3">
                                            <h5>What is the interest rate?</h5> 
                                        </div>
                                    </div>: 
                                    (
                                    <div className="d-flex">
                                                <button onClick={()=> {setToggleFour(!toggleFour)}}>-</button>
                                                <div className="d-flex flex-column ms-3">
                                                    <h5>What is the interest rate?</h5>
                                                    <p>The interest rate is 3.0% monthly which arrives at 36% APR. We only 
                                                    accrue interest daily, so if you take a ₦100,000 credit today and 
                                                    pay back in 3 days you would be paying ₦99*3 days as interest on 
                                                    the principal. Click here for more explanation </p> 
                                                </div>
                                    </div>)}
                            </div>
                            <div className="faq-img d-flex">
                                {toggleFive ?
                                        <div className="d-flex">
                                            <button onClick={()=> {setToggleFive(!toggleFive)}} className="text-center">+</button>
                                            <div className="ms-3">
                                                <h5>How much credit can i get?</h5>
                                            </div> 
                                        </div>: 
                                        (
                                        <div className="d-flex">
                                                    <button onClick={()=> {setToggleFive(!toggleFive)}}>-</button>
                                                    <div className="d-flex flex-column ms-3">
                                                        <h5>How much credit can i get?</h5>
                                                        <p>The minimum credit limit Lucred gives is ₦50,000 and the maximum is ₦200,000. Kindly note that this limit is subject to periodic review.</p>
                                                    </div>
                                        </div>)}
                            </div>
                        
                        
                        
                            <div className="faq-img d-flex">
                                {toggleSix ?
                                        <div className="d-flex">
                                            <button onClick={()=> {setToggleSix(!toggleSix)}} className="text-center">+</button>
                                            <div className="ms-3">
                                                <h5>Have more questions?</h5> 
                                            </div>
                                        </div>: 
                                        (
                                        <div className="d-flex">
                                                    <button onClick={()=> {setToggleSix(!toggleSix)}}>-</button>
                                                    <div className="d-flex flex-column ms-3">
                                                        <h5>Have more questions?</h5>
                                                        <p>We are here to answer any questions you have; send us an email
                                                            to hello@lucred.co or reach us on any of our social platforms.</p> 
                                                    </div>
                                        </div>)}
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
            

        </div>
    )
}

export default Servive;