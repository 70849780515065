import React from "react";
import Navigation from '../components/navigation';
import Footer from '../components/Footer';
import HeadCircle from '../images/head-circle.png'
import Lucred from '../images/lucred.png'
import HandsTogether from '../images/hands-together.png'
import Freepik from '../images/freepik.png'
import OnlineWorld from '../images/online-world.png'
import CustomerRelationship from '../images/customer-relationship.png'
import Chat from "../components/Chat";
import { Link } from "react-router-dom";


function Customer(){
    return(
        <>

            {/* This page is for New Merchant registration */}

            <Navigation />
            <main>
                <div className="container">
                    <section>
                        <div className="maserati-div d-flex">
                            <div className="maserati-text-wrapper">
                                {/* <div className="d-flex">
                                    <img src={HeadCircle} alt='lucred-head' className="head-circle"></img>
                                    <img src={Lucred} alt='lucred-head' className="lucred"></img>
                                </div> */}
                                <div className="maserati-text">
                                    <h3>Hop In our Maserati!</h3>
                                </div>
                                <form className="maserati-form d-flex flex-column">
                                    <div className="d-flex flex-column mb-4">
                                        <label>Full Name</label>
                                        <div className="d-flex justify-content-between" id="fullname">
                                            <input type="text" placeholder="     First Name"></input>
                                            <input type="text" placeholder="     Last Name"></input>
                                        </div>
                                    </div>
                                    
                                    <div className="d-flex flex-column mb-4">
                                        <label >Email</label>
                                        <input type="email" placeholder="      comicfooty@gmail.com"></input>
                                    </div>
                                    <div className="d-flex flex-column mb-4">
                                        <label>Phone Number</label>
                                        <input type="text" placeholder="      08030601026"></input>
                                    </div>
                                    <div className="d-flex flex-column mb-4">
                                        <label>Name of Store/Business</label>
                                        <input type="text" placeholder="      ComicFooty"></input>
                                    </div>
                                    <div className="d-flex flex-column mb-4">
                                        <label>Location of Store/Business</label>
                                        <input type="text" placeholder="      Your store address"></input>
                                    </div>
                                    <div className="d-flex flex-column mb-4">
                                        <label>Number of Store/Outlet</label>
                                        <input list="outlets" name="outlet" id="outlet" placeholder="     "></input>
                                        <datalist id="outlets">
                                            <option value="1"></option>
                                            <option value="2-5"></option>
                                            <option value="6-10"></option>
                                            <option value="11-50"></option>
                                            <option value="50+"></option>
                                        </datalist>
                                    </div>
                                    <div className="d-flex flex-column mb-4">
                                        <label>Annual Revenue/Turnover</label>
                                        <input list="millions" name="million" id="million" placeholder=""></input>
                                        <datalist id="millions">
                                            <option value="0 - 1,000,000"></option>
                                            <option value="1,000,000 - 10,000,000"></option>
                                            <option value="10,000,000 - 50,000,000"></option>
                                            <option value="50,000,000 - 100,000,000"></option>
                                            <option value="100,000,000+"></option>
                                        </datalist>
                                    </div>
                                    <div className="d-flex justify-content-end ">
                                        <button className="">Submit</button>
                                    </div>
                                </form>
                                <div>
                                    <p>Already have an account? <Link to="https://merchant.Lucred.co">login here</Link></p>
                                </div>

                            </div>
                            <div className="maserati-img not-show">
                                <img src={HandsTogether} alt='hands-together'></img>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="support-wrapper">
                            <div className="text-center support-text">
                                <h3>WE WORK SEAMLESSLY WITH OUR CLIENTS FOR THE LONG-TERM</h3>
                                <p>Reach out to us if you need any help</p>
                            </div>
                            <div className="d-flex justify-content-evenly support-div text-center ">
                                <div className="card">
                                    <h4 >Let's Talk</h4>
                                    <p>Have a chat with a <br></br>member of our team.</p>
                                    <p><span>Call Us </span> .</p>
                                    <img src={Freepik} alt='freepik'></img>

                                </div>
                                <div className="card middle-card">
                                    <h4>Support</h4>
                                    <p>Get involved in <br></br>making the dream <br></br>a reality today.</p>
                                    <p><span>Follow Us </span> .</p>
                                    <img src={OnlineWorld} alt='freepik'></img>

                                </div>
                                <div className="card">
                                    <h4>We are Social</h4>
                                    <p className="">We're here <br></br>to help with <br></br>any questions <br></br>or queries.</p>
                                    <p> <span>Help & Support</span> .</p>
                                    <img src={CustomerRelationship} alt='freepik'></img>

                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            </main>
            <Chat />
            <Footer />

        </>
    )
}

export default Customer;